import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box } from '../../../app/models/models';
import { NavLink } from 'react-router-dom';
import urls from '../../../app/common/urls';
import RentBoxWizzard from '../RentBox/RentBoxWizzard';
import ReserveBoxWizzard from '../RentBox/ReserveBoxWizzard';
import AdminRequiredWrapper from '../../../app/common/AdminRequiredWrapper';
import BoxEditModal from '../BoxUpdate/BoxEditModal';
import { useStore } from '../../../app/stores/store';
import { useTranslation } from 'react-i18next';

interface Props {
    box: Box
}

export default observer(function BoxData({ box }: Props) {
    const [t] = useTranslation('common');
    const [rent, setRent] = useState(false);
    const [reserve, setReserve] = useState(false);

    const { boxStore: { loadItem } } = useStore();
    const [editItemId, setEditItemId] = useState('');

    const handleEdit = () => {
        setEditItemId(box.id);
    }

    const handleCloseModal = () => {
        setEditItemId('');
        loadItem(box.id);
    }

    return (
        <>
            {/*begin::Card*/}
            <div className="card mb-5 mb-xl-8">
                {/*begin::Card body*/}
                <div className="card-body pt-0 pt-lg-1">
                    {/*begin::Summary*/}
                    {/*begin::Card*/}
                    <div className="">
                        {/*begin::Card body*/}
                        <div className="d-flex flex-center flex-column pt-12 p-9 px-0">
                            {/*begin::Name*/}
                            <span className="fs-1 text-gray-800 fw-bolder mb-3">{t('--BoxDetails.Box details')}</span>
                            <span className="fs-3 text-gray-800 fw-bolder mb-3">#{box.number}</span>
                            {/*end::Name*/}
                            {/*begin::Position*/}
                            <div className="mb-9 mt-5">
                                {/*begin::Badge*/}
                                {box.isRented ? (
                                    <span className="badge badge-lg badge-light-primary d-inline">{t('--Common.Rented')}</span>
                                ) : (
                                    <span className="badge badge-lg badge-light-success d-inline">{t('--Common.Free to rent')}</span>
                                )}
                                {/*begin::Badge*/}
                            </div>
                            {/*end::Position*/}
                        </div>
                        {/*end::Card body*/}
                    </div>
                    {/*end::Card*/}
                    {/*end::Summary*/}
                    {/*begin::Details toggle*/}
                    <div className="d-flex flex-stack fs-4 py-3">
                        <div className="fw-bolder">{t('--BoxDetails.Basic data')}
                        </div>
                    </div>
                    {/*end::Details toggle*/}
                    <div className="separator"></div>
                    {/*begin::Details content*/}
                    <div className="collapse show">
                        <div className="pb-5 fs-6">
                            {/*begin::Details item*/}
                            <div className="fw-bolder mt-5">{t('--Common.Size')}</div>
                            <div className="text-gray-600">{box.area} m2</div>
                            {/*begin::Details item*/}
                            {/*begin::Details item*/}
                            <div className="fw-bolder mt-5">{t('--Common.Price')}</div>
                            <div className="text-gray-600">
                                <span className="text-gray-600">{box.monthlyPrice}zł / month</span>
                            </div>
                            {/*begin::Details item*/}
                            {/*begin::Details item*/}
                            <div className="fw-bolder mt-5">{t('--Common.Status')}</div>
                            {box.isRented ?
                                (<div className="text-gray-600">{t('--BoxDetails.Rented until {{date}', { date: box.contractExpiresOn, number: box.currentContractExpiresInDays })}</div>)
                                : (<div className="text-gray-600">{t('--Common.Free to rent')}</div>)}
                            {/*begin::Details item*/}

                        </div>
                    </div>
                    {box.isRented &&
                        <>
                            <div className="d-flex flex-stack fs-4 py-3">
                                <div className="fw-bolder">{t('--BoxDetails.Current box owner')}
                                </div>
                                <span data-bs-toggle="tooltip" data-bs-trigger="hover" title="Edit customer details">
                                    <NavLink to={urls.customerDetails(box.currentOwnerId)} className="btn btn-sm btn-light-primary">{t('--Common.View')}</NavLink>
                                </span>
                            </div>
                            {/*end::Details toggle*/}
                            <div className="separator"></div>
                            {/*begin::Details content*/}
                            <div className="collapse show">
                                <div className="pb-5 fs-6">
                                    {/*begin::Details item*/}
                                    <div className="fw-bolder mt-5">{t('--Common.Name')}</div>
                                    <div className="text-gray-600">{box.currentOwnerName}</div>
                                    {/*begin::Details item*/}
                                    {/*begin::Details item*/}
                                    <div className="fw-bolder mt-5">{t('--Common.Email')}</div>
                                    <div className="text-gray-600">
                                        <a href="\" className="text-gray-600 text-hover-primary">{box.currentOwnerEmail}</a>
                                    </div>
                                    {/*begin::Details item*/}
                                    {/*begin::Details item*/}
                                    <div className="fw-bolder mt-5">{t('--Common.Phone')}</div>
                                    <div className="text-gray-600">{box.currentOwnerPhone}</div>
                                    {/*begin::Details item*/}
                                </div>
                            </div>
                        </>}
                    {/*end::Details content*/}

                    <button onClick={() => setRent(true)} className={'btn btn-success btn-shadow-hover font-weight-bolder w-100 py-3'}>{t('--Common.Rent')}</button>
                    <button onClick={() => setReserve(true)} className={'btn btn-primary btn-shadow-hover font-weight-bolder w-100 py-3 mt-3'}>{t('--BoxDetails.Make reservation')}</button>
                    <AdminRequiredWrapper>
                        <button onClick={() => handleEdit()} className={'btn btn-warning btn-shadow-hover font-weight-bolder w-100 py-3 mt-3'}>{t('--BoxDetails.Edit box')}</button>
                    </AdminRequiredWrapper>
                </div>
                {/*end::Card body*/}
            </div>
            {/*end::Card*/}
            {rent &&
                <RentBoxWizzard box={box}
                    onClose={() => {
                        setRent(false);
                    }}
                    onSubmit={() => {
                        setRent(false);
                        window.location.reload();
                    }}
                />}
            {reserve &&
                <ReserveBoxWizzard box={box}
                    onClose={() => {
                        setReserve(false);
                    }}
                    onSubmit={() => {
                        setRent(false);
                        window.location.reload();
                    }} />}
            {editItemId &&
                <BoxEditModal
                    OnClose={handleCloseModal}
                    id={editItemId}
                />}
        </>
    )
})

