import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Contract, TypeOfPayment } from '../../../app/models/models';
import { NavLink } from 'react-router-dom';
import urls from '../../../app/common/urls';
import ProgressBar from '../../../app/elements/ProgressBar';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from '../../../app/common/ConfirmationModal';
import { useStore } from '../../../app/stores/store';
import AdminRequiredWrapper from '../../../app/common/AdminRequiredWrapper';
import { history } from "../../..";

interface Props {
    contract: Contract
}
export default observer(function ContractData({ contract }: Props) {
    const [t] = useTranslation('common');
    const { contractStore } = useStore();

    const [showInvoiceEntireContractConfirmation, setShowInvoiceEntireContractConfirmation] = useState(false)
    const handleYesInvoiceEntireContractConfirmation = (typeOfPayment: TypeOfPayment) => {
        contractStore.invoiceEntireRemainingPeriod(contract.id, typeOfPayment).then(() => {
            setShowInvoiceEntireContractConfirmation(false);
            window.location.reload();
        });
    }

    const [showInvoiceOnePeriodConfirmation, setShowInvoiceOnePeriodConfirmation] = useState(false)
    const handleYesInvoiceOnePeriodConfirmation = (typeOfPayment: TypeOfPayment) => {
        contractStore.invoiceNextUninvoicedPeriod(contract.id, typeOfPayment).then(() => {
            setShowInvoiceOnePeriodConfirmation(false);
            window.location.reload();
        });
    }

    const [showDeleteContractConfirmation, setShowDeleteContractConfirmation] = useState(false)
    const handleYesDeleteContractConfirmation = () => {
        contractStore.delete(contract.id).then(() => {
            setShowDeleteContractConfirmation(false);
            history.push(urls.contractsList());
        }).catch(()=>{
            alert("There was an error! Contact the administrator! Please remember that contract with invoices must not be deleted");
            setShowDeleteContractConfirmation(false);
        });
    }

    const [showFinishContractConfirmation, setShowFinishContractConfirmation] = useState(false)
    const handleYesFinishContractConfirmation = () => {
        contractStore.finish(contract.id).then(() => {
            setShowFinishContractConfirmation(false);
            window.location.reload();
        }).catch(()=>{
            alert("There was an error! Contact the administrator!");
            setShowFinishContractConfirmation(false);
        });
    }

    return (
        <>
            {/*begin::Card*/}
            <div className="card mb-5 mb-xl-8">
                {/*begin::Card body*/}
                <div className="card-body pt-0 pt-lg-1">
                    {/*begin::Summary*/}
                    {/*begin::Card*/}
                    <div className="">
                        {/*begin::Card body*/}
                        <div className="card-body d-flex flex-center flex-column pt-12 p-9 px-0">
                            {/*begin::Name*/}
                            <span className="fs-1 text-gray-800 fw-bolder mb-3">{t('--Contracts.Contract details')}</span>
                            <span className="fs-3 text-gray-800 fw-bolder mb-3">{contract.customerFullName} - #{contract.boxNumber}</span>
                            {/*end::Name*/}
                            {/*begin::Position*/}
                            <div className="mb-5 mt-5">
                                {/*begin::Badge*/}
                                {contract.isFinished &&
                                    <span className="badge badge-lg badge-light-success d-inline">{t('--Common.Finished')}</span>}
                                {contract.isFuture &&
                                    <span className="badge badge-lg badge-light-info d-inline">{t('--Common.Future')}</span>}
                                {!contract.isFuture && !contract.isFinished &&
                                    <span className="badge badge-lg badge-light-primary d-inline">{t('--Common.In Progress')}</span>}

                                {/*begin::Badge*/}
                            </div>
                            <div className="mb-1 mt-4">
                                {!contract.isSigned &&
                                    <span className="badge badge-lg badge-danger d-inline">{t('--Common.Not signed')}</span>}

                                {/*begin::Badge*/}
                            </div>
                            {/*end::Position*/}
                        </div>
                        {/*end::Card body*/}
                    </div>
                    {/*end::Card*/}
                    {/*end::Summary*/}
                    {/*begin::Details toggle*/}
                    <div className="d-flex flex-stack fs-4 py-3">
                        <div className="fw-bolder">{t('--Contracts.Contract data')}
                        </div>
                    </div>
                    {/*end::Details toggle*/}
                    <div className="separator"></div>
                    {/*begin::Details content*/}
                    <div className="collapse show">
                        <div className="pb-5 fs-6">
                            <div className="fw-bolder mt-5">{t('--Contracts.Progress')}</div>
                            <ProgressBar percentage={contract.percentageDone} />
                            <div className="row">
                                <div className="col-7">
                                    <div className="fw-bolder mt-5">{t('--Contracts.Period')}</div>
                                    <div className="text-gray-600">{contract.from} - {contract.to}</div>
                                </div>
                                <div className="col-5">
                                    <div className="fw-bolder mt-5">{t('--Contracts.Signed at')}</div>
                                    <div className="text-gray-600">{contract.signedAt}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-stack fs-4 py-3">
                        <div className="fw-bolder">{t('--Contracts.Customer data')}
                        </div>
                        <span>
                            <NavLink to={urls.customerDetails(contract.customerId)} className="btn btn-sm btn-light-primary">{t('--Common.View')}</NavLink>
                        </span>
                    </div>
                    {/*end::Details toggle*/}
                    <div className="separator"></div>
                    {/*begin::Details content*/}
                    <div className="collapse show">
                        <div className="pb-5 fs-6">
                            {/*begin::Details item*/}
                            <div className="fw-bolder mt-5">{t('--Common.Name')}</div>
                            <div className="text-gray-600">{contract.customerFullName}</div>
                            {/*begin::Details item*/}
                            {/*begin::Details item*/}
                            <div className="fw-bolder mt-5">{t('--Common.Email')}</div>
                            <div className="text-gray-600">
                                <a href="\" className="text-gray-600 text-hover-primary">{contract.customerEmail}</a>
                            </div>
                            {/*begin::Details item*/}
                            {/*begin::Details item*/}
                            <div className="fw-bolder mt-5">{t('--Common.Phone')}</div>
                            <div className="text-gray-600">{contract.customerPhone}</div>
                            {/*begin::Details item*/}
                        </div>
                    </div>
                    {/*end::Details content*/}
                    <div className="d-flex flex-stack fs-4 py-3">
                        <div className="fw-bolder">{t('--Contracts.Box data')}
                        </div>
                        <span>
                            <NavLink to={urls.boxDetails(contract.boxId)} className="btn btn-sm btn-light-primary">{t('--Common.View')}</NavLink>
                        </span>
                    </div>
                    {/*end::Details toggle*/}
                    <div className="separator"></div>
                    {/*begin::Details content*/}
                    <div className="collapse show">
                        <div className="pb-5 fs-6">
                            <div className="row">
                                <div className="col-4">
                                    <div className="fw-bolder mt-5">{t('--Contracts.Box number')}</div>
                                    <div className="text-gray-600">#{contract.boxNumber}</div>
                                </div>
                                <div className="col-4">
                                    <div className="fw-bolder mt-5">{t('--Common.Size')}</div>
                                    <div className="text-gray-600">{contract.boxArea} m2</div>
                                </div>
                                <div className="col-4">
                                    <div className="fw-bolder mt-5">{t('--Common.Price')}</div>
                                    <div className="text-gray-600">{contract.boxMonthlyPrice} / m</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*end::Details content*/}
                    {!contract.isSigned &&
                        <a
                            href={`${process.env.REACT_APP_API_URL}/documentprint/contract/${contract.id}`} target="blank"
                            className={'btn btn-success btn-shadow-hover font-weight-bolder w-100 py-3'}>
                            {t('--Contracts.View and sign the contract')}
                        </a>
                    }
                    {contract.isSigned &&
                        <a
                            href={`${process.env.REACT_APP_API_URL}/documentprint/contract/${contract.id}/signed`} target="blank"
                            className={'btn btn-success btn-shadow-hover font-weight-bolder w-100 py-3'}>
                            {t('--Contracts.View contract')}
                        </a>
                    }
                    {contract.canInvoice &&
                        <>
                            <button className="btn btn-warning btn-shadow-hover font-weight-bolder w-100 py-3 mt-5" onClick={() => setShowInvoiceOnePeriodConfirmation(true)}>{t("--Contract.Invoice one period")}</button>
                            <button className="btn btn-danger btn-shadow-hover font-weight-bolder w-100 py-3 mt-5" onClick={() => setShowInvoiceEntireContractConfirmation(true)}>{t("--Contract.Invoice the rest")}</button>
                        </>
                    }
                    <AdminRequiredWrapper>
                        <>
                            {!contract.isSigned && 
                                <button className="btn btn-danger btn-shadow-hover font-weight-bolder w-100 py-3 mt-5" onClick={() => setShowDeleteContractConfirmation(true)}>{t("--Contracts.Delete contract")}</button>}
                            {!contract.isFuture && !contract.isFinished &&
                                <button className="btn btn-danger btn-shadow-hover font-weight-bolder w-100 py-3 mt-5" onClick={() => setShowFinishContractConfirmation(true)}>{t("--Contracts.Finish contract")}</button>}
                        </>
                    </AdminRequiredWrapper>

                </div>
                {/*end::Card body*/}
            </div>
            {/*end::Card*/}
            {showInvoiceEntireContractConfirmation &&
                <ConfirmationModal
                    OnClose={() => setShowInvoiceEntireContractConfirmation(false)}
                    label={t('--Contracts.Are you sure you want to invoice entire remaining conract?')}
                    overrideYesFunctions={[
                        {
                            text: "Tak, płatność kartą",
                            OnClick: ()=>{handleYesInvoiceEntireContractConfirmation(TypeOfPayment.Card)}
                        },
                        {
                            text: "Tak, płatność przelewem",
                            OnClick: ()=>{handleYesInvoiceEntireContractConfirmation(TypeOfPayment.Transfer)}
                        }
                    ]}
                />}
            {showInvoiceOnePeriodConfirmation &&
                <ConfirmationModal
                    OnClose={() => setShowInvoiceOnePeriodConfirmation(false)}
                    label={t('--Contracts.Are you sure you want to invoice one upcoming and not paid period?')}
                    overrideYesFunctions={[
                        {
                            text: "Tak, płatność kartą",
                            OnClick: ()=>{handleYesInvoiceOnePeriodConfirmation(TypeOfPayment.Card)}
                        },
                        {
                            text: "Tak, płatność przelewem",
                            OnClick: ()=>{handleYesInvoiceOnePeriodConfirmation(TypeOfPayment.Transfer)}
                        }
                    ]}
                />}

            {showDeleteContractConfirmation &&
                <ConfirmationModal
                    OnClose={() => setShowDeleteContractConfirmation(false)}
                    OnYes={handleYesDeleteContractConfirmation}
                    label={t('--Contracts.Are you sure you want to delete this contract?')}
                />}

            {showFinishContractConfirmation &&
                <ConfirmationModal
                    OnClose={() => setShowFinishContractConfirmation(false)}
                    OnYes={handleYesFinishContractConfirmation}
                    label={t('--Contracts.Are you sure you want to finish this contract?')}                    
                />}
        </>
    )
})

