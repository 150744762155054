const formatDate = (date?: Date) => {
    if (!date) return '';
    return `${date!.getFullYear()}.${pad(date.getMonth() + 1)}.${pad(date.getDate())}`
};

const pad = (i: number) => {
    return String(i).padStart(2, '0');
};

export { formatDate, pad };
