import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import urls from '../../../app/common/urls';
import InvoiceBadge from '../../../app/elements/InvoiceBadge';
import PaginationBar from '../../../app/layout/PaginationBar';
import { PagingParams } from '../../../app/models/pagination';
import { useStore } from "../../../app/stores/store";

export default observer(function InvoicesPage() {
    const [t] = useTranslation('common');
    const { invoiceStore } = useStore();
    const { loadItems } = invoiceStore;
    const { setPredicate, setPagingParams, pagination, clearPredicateAndPaging } = invoiceStore.filteringAndPaginationController;

    function handleGetNext() {
        setPagingParams(new PagingParams(pagination!.currentPage + 1));
        loadItems();
    }

    function handleGetPrev() {
        setPagingParams(new PagingParams(pagination!.currentPage - 1));
        loadItems();
    }

    const handleFilterChange = (event: any) => {
        setPredicate('filter', event.target.value);
    }

    const handleTimeChange = (event: any) => {
        setPredicate('from', event.target.value);
    }

    const handleSearchChange = (event: any) => {
        const text = event.target.value;
        if (text === '') {
            setPredicate('search', event.target.value)
        }
        else if (text.length >= 3) {
            setPredicate('search', event.target.value)
        }
    }

    useEffect(() => {
        clearPredicateAndPaging();
        invoiceStore.loadItems();
    }, [invoiceStore, clearPredicateAndPaging])

    return (
        <>
            <div id="kt_content_container" className="container">
                {/*begin::Table*/}
                <div className="card card-flush mt-6 mt-xl-9">
                    {/*begin::Card header*/}
                    <div className="card-header mt-5">
                        {/*begin::Card title*/}
                        <div className="card-title flex-column">
                            <h3 className="fw-bolder mb-1">{t('--Navigation.Invoices')}</h3>
                            <div className="fs-6 text-gray-400">{t('--Common.{{number}} in total', {number: pagination?.totalItems})}</div>
                        </div>
                        {/*begin::Card title*/}
                        {/*begin::Card toolbar*/}
                        <div className="card-toolbar my-1">
                            {/*begin::Select*/}
                            <div className="me-6 my-1">
                                <select name="year" onChange={handleTimeChange} className="w-200px form-select form-select-solid form-select-sm">
                                <option value="All" >{t('--Common.All time')}</option>
                                    <option value="thisyear">{t('--Common.This year')}</option>
                                    <option value="thismonth">{t('--Common.This month')}</option>
                                    <option value="last90days">{t('--Common.Last 90 days')}</option>
                                </select>
                            </div>
                            {/*end::Select*/}
                            {/*begin::Select*/}
                            <div className="me-4 my-1">
                                <select name="filter" onChange={handleFilterChange} className="w-200px form-select form-select-solid form-select-sm">
                                    <option value="all">{t('--Common.All')}</option>
                                    <option value="paid">{t('--Common.Paid')}</option>
                                    <option value="notpaid">{t('--Common.Not paid')}</option>
                                    <option value="due">{t('--Common.Due')}</option>
                                </select>
                            </div>
                            {/*end::Select*/}
                            {/*begin::Search*/}
                            <div className="d-flex align-items-center position-relative my-1">
                                {/*begin::Svg Icon | path: icons/duotone/General/Search.svg*/}
                                <span className="svg-icon svg-icon-3 position-absolute ms-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <rect x="0" y="0" width="24" height="24" />
                                            <path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                            <path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fillRule="nonzero" />
                                        </g>
                                    </svg>
                                </span>
                                {/*end::Svg Icon*/}
                                <input type="text" onChange={handleSearchChange} className="form-control form-control-solid form-select-sm w-250px ps-9" placeholder={t('--Common.Search (3 letters mi')} />
                            </div>
                            {/*end::Search*/}
                        </div>
                        {/*begin::Card toolbar*/}
                    </div>
                    {/*end::Card header*/}
                    {/*begin::Card body*/}
                    <div className="card-body pt-0">
                        {/*begin::Table container*/}
                        <div className="table-responsive">
                            {/*begin::Table*/}
                            <table className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bolder">
                                {/*begin::Head*/}
                                <thead className="fs-7 text-gray-400 text-uppercase">
                                    <tr>
                                        <th className="min-w-200px">{t('--Invoices.ISSUED TO / FOR PERI')}</th>
                                        <th className="min-w-90px">{t('--Invoices.FOR BOX')}</th>
                                        <th className="min-w-150px">{t('--Invoices.ISSUED ON')}</th>
                                        <th className="min-w-90px">{t('--Invoices.AMOUNT')}</th>
                                        <th className="min-w-90px">{t('--Invoices.TITLE')}</th>
                                        <th className="min-w-90px"></th>
                                        <th className="min-w-50px"></th>
                                        <th className="min-w-50px text-end"></th>
                                    </tr>
                                </thead>
                                {/*end::Head*/}
                                {/*begin::Body*/}
                                <tbody className="fs-6">
                                    {invoiceStore.items.map((i) => (
                                        <tr key={i.id}>
                                            <td>
                                                {/*begin::User*/}
                                                <div className="d-flex align-items-center">
                                                    {/*begin::Info*/}
                                                    <div className="d-flex flex-column justify-content-center">
                                                        <NavLink to={urls.customerDetails(i.customerId)} className="fs-6 text-gray-800 text-hover-primary">{i.issuedToName}</NavLink>
                                                        <div className="fw-bold text-gray-400">{i.issuedForPeriodFrom} - {i.issuedForPeriodTo}</div>
                                                    </div>
                                                    {/*end::Info*/}
                                                </div>
                                                {/*end::User*/}
                                            </td>
                                            <td>{i.boxNumber}</td>
                                            <td>{i.issuedOn}</td>
                                            <td>{i.amount}</td>
                                            <td>{i.title}</td>
                                            <td>
                                                <InvoiceBadge invoice={i} />
                                            </td>
                                            <td>
                                            {!i.isSent &&
                                                <i className="fa fa-exclamation-triangle me-1 text-danger fs-5"></i>}
                                        </td>
                                            <td className="text-end">
                                                <NavLink to={urls.invoiceDetails(i.id)} className="btn btn-light btn-sm">{t('--Common.Details')}</NavLink>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                                {/*end::Body*/}
                            </table>
                            {/*end::Table*/}
                        </div>
                        {/*end::Table container*/}
                        {pagination &&
                            <PaginationBar pagination={pagination!} handleGetNext={handleGetNext} handleGetPrev={handleGetPrev} />}
                    </div>
                    {/*end::Card body*/}
                </div>
                {/*end::Card*/}
            </div>
        </>
    )
})