import { observer } from 'mobx-react-lite';
import React from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { Box, BoxStatistics, Reservation } from '../../../app/models/models';
import { useStore } from '../../../app/stores/store';
import BoxCalendar from './BoxCalendar';
import BoxContractPercentage from './BoxContractPercentage';
import BoxContracts from './BoxContracts';
import BoxData from './BoxData';
import BoxInvoices from './BoxInvoices';
import BoxReservations from './BoxReservations';
import BoxStatiscticsChart from './BoxStatisticsChart';
import BoxTimeline from './BoxTimeline';

export default observer(function BoxPage() {
    var { boxStore, contractStore, invoiceStore, reservationStore } = useStore();
    const { id } = useParams<{ id: string }>();
    const { selectedBox: box, selectedBoxStatistics: boxStatistics, selectedBoxCalendar: boxCalendar } = boxStore;

    useEffect(() => {
        boxStore.loadItem(id);
        contractStore.filteringAndPaginationController.clearPredicateAndPaging();
        invoiceStore.filteringAndPaginationController.clearPredicateAndPaging();
        reservationStore.filteringAndPaginationController.clearPredicateAndPaging();

        contractStore.filteringAndPaginationController.setPredicate('boxId', id);
        invoiceStore.filteringAndPaginationController.setPredicate('boxId', id);
        reservationStore.filteringAndPaginationController.setPredicate('boxId', id);
    }, [
        id,
        boxStore.loadItem, boxStore,
        contractStore, contractStore.filteringAndPaginationController.setPredicate,
        invoiceStore, invoiceStore.filteringAndPaginationController.setPredicate,
        reservationStore, reservationStore.filteringAndPaginationController.setPredicate])

    return (
        <>
            {box &&
                <div id="kt_content_container" className="container">
                    {box.currentOwnerName ?
                        <WithOwnerLayout box={box} boxStatistics={boxStatistics!} boxReservations={reservationStore.items} />
                        :
                        <NoOwnerLayout box={box} boxStatistics={boxStatistics!} boxReservations={reservationStore.items} />
                    }
                    <div className="separator" style={{ borderBottom: "2px solid #dddddd" }}></div>
                    <BoxContracts />
                    <div className="separator mt-10" style={{ borderBottom: "2px solid #dddddd" }}></div>
                    <BoxInvoices />
                    <BoxCalendar boxCalendar={boxCalendar!} />
                </div>}
        </>
    )
})

interface Props {
    box: Box,
    boxStatistics: BoxStatistics,
    boxReservations: Reservation[]
}

function WithOwnerLayout({ box, boxStatistics, boxReservations }: Props) {
    return (
        <>
            <div className="row">
                <div className="col-4">
                    <BoxData box={box} />
                </div>

                <div className="col-4">
                    <BoxStatiscticsChart boxStatistics={boxStatistics!} />
                    <BoxContractPercentage boxStatistics={boxStatistics!} />
                </div>

                <div className="col-4">
                    <BoxTimeline boxStatistics={boxStatistics!} />
                    <BoxReservations boxReservations={boxReservations} />
                </div>
            </div>
        </>
    )
}

function NoOwnerLayout({ box, boxStatistics, boxReservations }: Props) {
    return (
        <>
            <div className="row">
                <div className="col-4">
                    <BoxData box={box} />
                </div>
                <div className="col-4">
                    <BoxStatiscticsChart boxStatistics={boxStatistics!} />
                    <BoxReservations boxReservations={boxReservations} />
                </div>
                <div className="col-4">
                    <BoxTimeline boxStatistics={boxStatistics!} />
                </div>
            </div>
        </>
    )
}