import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Customer, CustomerAddOrUpdate } from "../models/models";
import FilteringAndPaginationController, { StoreWithLoadItems } from "../common/filteringAndPaginationController";

export default class CustomerStore implements StoreWithLoadItems {
    registry = new Map<string, Customer>();
    selectedItem: Customer | undefined = undefined;
    filteringAndPaginationController = new FilteringAndPaginationController(this);

    constructor() {
        makeAutoObservable(this);
    }

    loadItems = async () => {
        const result = await agent.Customers.list(this.filteringAndPaginationController.axiosParams);
        this.filteringAndPaginationController.setPagination(result.pagination);
        runInAction(() => {
            this.registry.clear();
            result.data.forEach(b => {
                this.registry.set(b.id, b);
            });
        })
    }

    loadItem = async (id: string) => {
        this.selectedItem = await agent.Customers.details(id);
        return this.selectedItem;
    }

    createOrUpdateItem = async (customer: CustomerAddOrUpdate) => {
        if(customer.id){
            return agent.Customers.update(customer);
        }else{
            return agent.Customers.create(customer);
        }
    }

    get items() {
        return Array.from(this.registry.values());
    }
}