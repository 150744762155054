import React from 'react';

export default function Footer() {
    return (
        <>
            <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
                <div
                    className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">
                    <div className="text-dark order-2 order-md-1">
                        <span className="text-muted fw-bold me-1">2021©</span>
                        <a href="http://www.candosolutions.pl" target="_blank" rel="noreferrer" className="text-gray-800 text-hover-primary">Self Storage Operator</a>
                    </div>
                </div>
            </div>
        </>
    )
}