import { Route } from 'react-router-dom';
import BoxesPage from '../../features/boxes/BoxesList/BoxesPage';
import BoxPage from '../../features/boxes/BoxDetails/BoxPage';
import DashboardPage from '../../features/dashboard/DashboardPage';
import Footer from './Footer';
import Header from './Header';
import NavBar from './NavBar';
import InvoicesPage from '../../features/invoices/InvoicesList/InvoicesPage';
import urls from '../common/urls';
import ContractsPage from '../../features/contracts/ContractsList/ContractsPage';
import CustomersPage from '../../features/customers/CustomersList/CustomersPage';
import ReservationsPage from '../../features/reservations/ReservationsList/ReservationsPage';
import CustomerPage from '../../features/customers/CutomerDetails/CustomerPage';
import ContractPage from '../../features/contracts/ContractDetails/ContractPage';
import InvoicePage from '../../features/invoices/InvoiceDetails/InvoicePage';
import MapPage from '../../features/map/MapPage';
import LoginPage from '../../features/users/LoginPage';
import { useStore } from '../stores/store';

import { observer } from 'mobx-react-lite';
import { Suspense, useEffect } from 'react';
import ForgotPasswordPage from '../../features/users/ForgotPasswordPage';
import ResetPasswordPage from '../../features/users/ResetPasswordPage';
import DiscountPlansPage from '../../features/discountPlans/DiscountPlansList/DiscountPlansPage';

export default observer(function App() {
  const { commonStore, userStore } = useStore();

  useEffect(() => {
    if (commonStore.token) {
      userStore.getUser().finally(() => commonStore.setAppLoaded())
    } else {
      commonStore.setAppLoaded();
    }
  }, [commonStore, userStore])

  if (!commonStore.appLoaded) {
    return <></>
  }

  if (!userStore.isLoggedIn) {
    return (
      <>
        <Route exact path={urls.root()} component={LoginPage} />
        <Route exact path={urls.forgotPassword()} component={ForgotPasswordPage} />
        <Route exact path={urls.resetPassword()} component={ResetPasswordPage} />
      </>
    )
  }

  return (
    <Suspense fallback="loading">
      <div className="page d-flex flex-row flex-column-fluid">
        <NavBar />
        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
          <Header />
          <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="post d-flex flex-column-fluid" id="kt_post">
              <Route exact path={urls.root()} component={BoxesPage} />
              <Route exact path={urls.boxesList()} component={BoxesPage} />
              <Route exact path={urls.invoicesList()} component={InvoicesPage} />
              <Route exact path={urls.contractsList()} component={ContractsPage} />
              <Route exact path={urls.customersList()} component={CustomersPage} />
              <Route exact path={urls.reservationsList()} component={ReservationsPage} />
              <Route exact path={urls.discountPlansList()} component={DiscountPlansPage} />

              <Route exact path='/boxes/:id' component={BoxPage} />
              <Route exact path='/customers/:id' component={CustomerPage} />
              <Route exact path='/contracts/:id' component={ContractPage} />
              <Route exact path='/invoices/:id' component={InvoicePage} />
              <Route exact path='/dashboard' component={DashboardPage} />
              <Route exact path='/map' component={MapPage} />
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </Suspense>
  )
})

