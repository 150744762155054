import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import ModalConfirmation from '../../app/elements/ModalConfirmation';
import Result from '../../app/api/result';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    OnClose(): void,
    requireOldPassword?: boolean,
    token?: string
    uid?: string
}

export default observer(function ChangePassword({ OnClose, requireOldPassword = true, token, uid }: Props) {
    const [t] = useTranslation('common');
    const { userStore } = useStore();
    const [result, setResult] = useState<Result | null>(null);
    const [isValid, setIsValid] = useState(false);
    const [warningClass, setWarningClass] = useState('text-danger');
    const [warningRepeatClass, setWarningRepeatClass] = useState('text-danger');
    const [warningCurrentClass, setWarningCurrentClass] = useState('text-danger');
    const [showPassword, setShowPassword] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [passwordStrength, setPasswordStrength] = useState(0);

    const handlePasswordChange = (event: any) => {
        const text = event.target.value;
        setPassword(text);
    }

    const handlePasswordRepeatChange = (event: any) => {
        const text = event.target.value;
        setPasswordRepeat(text);
    }

    const handleOldPasswordChange = (event: any) => {
        const text = event.target.value;
        setOldPassword(text);
    }

    const calculatePasswordStrength = (password: string) => {
        var result = 0;
        if (password.match(/(?=.*\d)/) !== null) result++;
        if (password.match(/(?=.*[a-z])/) !== null) result++;
        if (password.match(/(?=.*[A-Z])/) !== null) result++;
        if (password.length >= 8) result++;
        setPasswordStrength(result);
        return result;
    }

    const handleTogglePasswordFieldType = () => {
        setShowPassword(!showPassword);
    }

    const handleSubmit = () => {
        if (!token) {
            userStore.changePassword({ currentPassword: oldPassword, newPassword: password }).then((resultOfCall) => {
                setResult(resultOfCall);
            });
        } else {
            userStore.setNewPassword({ uid: uid!, token: token, newPassword: password }).then((resultOfCall) => {
                setResult(resultOfCall);
            });
        }
    }

    useEffect(() => {
        const passwordStrength = calculatePasswordStrength(password);

        const passwordStrongEnough = passwordStrength === 4;
        const passwordsAreEqual = password === passwordRepeat;
        const oldPasswordIsNotEmpty = oldPassword !== '' || !requireOldPassword;

        setIsValid(passwordStrongEnough && passwordsAreEqual && oldPasswordIsNotEmpty);
        setWarningRepeatClass(passwordsAreEqual ? 'text-muted' : 'text-danger')
        setWarningClass(passwordStrongEnough ? 'text-muted' : 'text-danger')
        setWarningCurrentClass(oldPasswordIsNotEmpty ? 'text-muted' : 'text-danger')
    }, [password, passwordRepeat, oldPassword, requireOldPassword])

    return (
        <>
            {!result &&
                <div>
                    {/*begin::Heading*/}
                    <div className="mb-13 text-center">
                        {/*begin::Title*/}
                        <h1 className="mb-3">{t('--Authentication.Change password')}</h1>
                        {/*end::Title*/}
                        {/*begin::Description*/}
                        <div className="text-gray-400 fw-bold fs-5">{t('--Authentication.Change your password')}</div>
                        {/*end::Description*/}
                    </div>
                    {/*end::Heading*/}
                    {requireOldPassword &&
                        <div className="fv-row mb-10 fv-plugins-icon-container">
                            <label className="form-label fw-bolder text-dark fs-6">{t('--Authentication.Current Password')}</label>
                            <input onChange={handleOldPasswordChange} className="form-control form-control-lg form-control-solid" type="password" placeholder="" name="oldPassword" autoComplete="off" />
                            <div className={`${warningCurrentClass} mt-2`}>{t('--Authentication.Enter current password')}</div>
                        </div>}
                    <div className="mb-10 fv-row fv-plugins-icon-container">
                        {/*begin::Wrapper*/}
                        <div className="mb-1">
                            {/*begin::Label*/}
                            <label className="form-label fw-bolder text-dark fs-6">{t('--Authentication.Password')}</label>
                            {/*end::Label*/}
                            {/*begin::Input wrapper*/}
                            <div className="position-relative mb-3">
                                <input onChange={handlePasswordChange} className="form-control form-control-lg form-control-solid" type={showPassword ? 'text' : 'password'} placeholder="" name="password" autoComplete="off" />
                                <span onClick={handleTogglePasswordFieldType} className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2">
                                    <i className="bi bi-eye-slash fs-2"></i>
                                    <i className="bi bi-eye fs-2 d-none"></i>
                                </span>
                            </div>
                            {/*end::Input wrapper*/}
                            {/*begin::Meter*/}
                            <div className="d-flex align-items-center mb-3">
                                <div className={`flex-grow-1 bg-secondary bg-active-${(passwordStrength === 4) ? 'success' : 'warning'} rounded h-5px me-2 ${(passwordStrength >= 1) ? 'active' : ''}`}></div>
                                <div className={`flex-grow-1 bg-secondary bg-active-${(passwordStrength === 4) ? 'success' : 'warning'} rounded h-5px me-2 ${(passwordStrength >= 2) ? 'active' : ''}`}></div>
                                <div className={`flex-grow-1 bg-secondary bg-active-${(passwordStrength === 4) ? 'success' : 'warning'} rounded h-5px me-2 ${(passwordStrength >= 3) ? 'active' : ''}`}></div>
                                <div className={`flex-grow-1 bg-secondary bg-active-${(passwordStrength === 4) ? 'success' : 'warning'} rounded h-5px me-2 ${(passwordStrength >= 4) ? 'active' : ''}`}></div>
                            </div>
                            {/*end::Meter*/}
                        </div>
                        {/*end::Wrapper*/}
                        {/*begin::Hint*/}
                        <div className={`${warningClass}`}>{t('--Authentication.Use 8 or more charac')}</div>
                        {/*end::Hint*/}
                        <div className="fv-plugins-message-container invalid-feedback"></div>
                    </div>
                    <div className="fv-row mb-10 fv-plugins-icon-container">
                        <label className="form-label fw-bolder text-dark fs-6">{t('--Authentication.Confirm Password')}</label>
                        <input onChange={handlePasswordRepeatChange} className="form-control form-control-lg form-control-solid" type="password" placeholder="" name="repeatPassword" autoComplete="off" />
                        <div className={`${warningRepeatClass} mt-2`}>{t('--Authentication.Passwords must match')}</div>
                    </div>
                    {/*begin::Actions*/}
                    <div className="text-center">
                        <button className="btn btn-white me-3" onClick={() => OnClose()}>{t('--Common.Cancel')}</button>
                        <button
                            onClick={() => handleSubmit()}
                            disabled={!isValid}
                            className="btn btn-primary">
                            <span className="indicator-label">{isValid ? t('--Common.Submit') :t('--Common.Form not valid')}</span>
                        </button>
                    </div>

                    {/*end::Actions*/}
                </div>}
            {result?.isSuccess &&
                <>
                    <ModalConfirmation message={`Password was changed successfully`} success />
                    <div className="swal2-actions mt-15">
                        <button type="button" className="swal2-confirm btn btn-primary me-5" onClick={() => OnClose()}>Ok</button>
                    </div>
                </>}
            {result && !(result?.isSuccess) &&
                <>
                    <ModalConfirmation message={`There was an error during changing the password`} success={false} errors={result.errors.map(e => e.message)} />
                    <div className="swal2-actions mt-15">
                        <button type="button" className="swal2-confirm btn btn-primary me-5" onClick={() => OnClose()}>{t('--Common.Ok, forget about it')}</button>
                        <button type="button" className="swal2-confirm btn btn-primary" onClick={() => { setResult(null); setPasswordStrength(0) }}>{t("--Common.Let's try again")}</button>
                    </div>
                </>}
        </>
    )
})