const urls = {
    root: () => '/',
    forgotPassword: () => '/forgot-password',
    resetPassword: () => '/reset-password/:uid/:token',

    dashboard: () => '/dashboard',
    map: () => '/map',
    boxesList: () => '/boxes',
    boxDetails: (id: string) => `/boxes/${id}`,
    boxRent: (id: string) => `/boxes/${id}/rent`,
    invoicesList: () => '/invoices',
    invoiceDetails: (id: string) => `/invoices/${id}`,
    customersList: () => '/customers',
    customerDetails: (id: string) => `/customers/${id}`,
    contractsList: () => '/contracts',
    contractDetails: (id: string) => `/contracts/${id}`,
    reservationsList: () => '/reservations',
    reservationDetails: (id: string) => `/reservations/${id}`,
    discountPlansList: () => '/discounts',
    dicountPlanDetails: (id: string) => `/discounts/${id}`,
}

export default urls;