import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Customer, DiscountPlan } from '../../../app/models/models';
import { useStore } from '../../../app/stores/store';
import Flatpickr from 'react-flatpickr';
import { NavLink } from 'react-router-dom';
import urls from '../../../app/common/urls';
import { formatDate } from '../../../app/common/helpers';
import { useTranslation } from 'react-i18next';

interface Props {
    box: Box
    onClose(): void
    onSubmit(): void
}

const numberOfSteps = 7;
const saveStep = 5;

export default observer(function RentBoxWizzard({ box, onClose, onSubmit }: Props) {
    const [t] = useTranslation('common');

    const [currentStep, setCurrentStep] = useState(1);
    const [customer, setCustomer] = useState<Customer | undefined>(undefined);
    const [discountPlans, setDiscountPlans] = useState<DiscountPlan[]>([]);
    const [from, setFrom] = useState<Date | undefined>(undefined);
    const [to, setTo] = useState<Date>();
    const [howManyMonths, setHowManyMonths] = useState(1);
    const [datesAreOk, setDatesAreOk] = useState(false);
    const [contractId, setContractId] = useState('');

    const { customerStore: store, boxStore, discountPlansStore } = useStore();
    const { setPredicate, clearPredicateAndPaging } = store.filteringAndPaginationController;

    useEffect(() => {
        clearPredicateAndPaging();
        store.loadItems();
        discountPlansStore.loadItems();
    }, [store, discountPlansStore, clearPredicateAndPaging])

    useEffect(() => {
        if (!from || !to) return;
        boxStore.checkAvailabilty(box.id, from!, to!).then(r => {
            setDatesAreOk(r.data.isAvailable);
        });
    }, [to, from, box.id, boxStore])

    // const handleToChange = (potentialTo: Date) => {
    //     setTo(potentialTo);
    // }

    const handleFromChange = (potentialFrom: Date) => {
        setFrom(potentialFrom);
        const newDate = calculateDateTo(potentialFrom, howManyMonths);
        setTo(newDate);
    }

    const handleHowManyMonthsChange = (event: any) => {
        if (!from) return;
        var text = event.target.value.toString();
        if (text === '') {
            setDatesAreOk(false);
            return;
        }
        const numberOfMonths = parseInt(text);
        if (numberOfMonths <= 0) {
            setDatesAreOk(false);
            return;
        }
        const newDate = calculateDateTo(from, numberOfMonths);
        setHowManyMonths(numberOfMonths);
        setTo(newDate);
    }

    const calculateDateTo = (start: Date, months: number) => {
        var newDate = new Date(start.getTime());
        newDate = addMonths(newDate, months);
        return newDate;
    }

    function addMonths(date: Date, months: number) {
        var d = date.getDate();
        date.setMonth(date.getMonth() + +months);
        if (date.getDate() !== d) {
            date.setDate(0);
        }
        date.setDate(date.getDate() - 1);
        return date;
    }

    const handleSearchChange = (event: any) => {
        const text = event.target.value;
        if (text === '' || text.length >= 3) {
            setPredicate('search', event.target.value, 50)
        }
    }

    const handleSelectCustomer = (customer: Customer) => {
        setCustomer(customer);
    }

    const handleToggleDiscountPlan = (plan: DiscountPlan) => {
        var plans = discountPlans.includes(plan)
            ? discountPlans.filter(i => i !== plan)
            : [...discountPlans, plan];

        setDiscountPlans(plans);

        //var plans = discountPlansStore.items
        //    .filter(p => plansIds.includes(p.id));

    }

    const handleSubmit = () => {
        onSubmit();
    }

    const handleContinue = () => {
        if (currentStep === saveStep) {
            if (!from || !to || !customer) alert('validation');
            boxStore.rent({
                customerId: customer!.id,
                boxId: box.id,
                from: from!,
                to: to!,
                discountPlans: discountPlans.map(x => x.id)
            }).then((r) => {
                setContractId(r.value)
            });
        }
        setCurrentStep(currentStep + 1);
    }

    const isCurrent = (step: number) => {
        if (step === currentStep) return 'current';
        return '';
    }

    const continueAvailable = () => {
        switch (currentStep) {
            case 1: return true;
            case 2: return customer;
            case 3: return from && to && datesAreOk;
        }
        return true;
    }

    return (
        <>
            {/*begin::Modal - Create App*/}
            <div className="modal fade show" style={{ display: "block" }}>
                {/*begin::Modal dialog*/}
                <div className="modal-dialog modal-dialog-centered mw-900px">
                    {/*begin::Modal content*/}
                    <div className="modal-content">
                        {/*begin::Modal header*/}
                        <div className="modal-header">
                            {/*begin::Modal title*/}
                            <h1 className="px-14 pt-2">Rent the box #{box.number}</h1>
                            {/*end::Modal title*/}
                            {/*begin::Close*/}
                            <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={onClose} >
                                {/*begin::Svg Icon | path: icons/duotone/Navigation/Close.svg*/}
                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                        </g>
                                    </svg>
                                </span>
                                {/*end::Svg Icon*/}
                            </div>
                            {/*end::Close*/}
                        </div>
                        {/*end::Modal header*/}
                        {/*begin::Modal body*/}
                        <div className="modal-body py-lg-10 px-lg-10">
                            {/*begin::Stepper*/}
                            <div className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid" id="kt_modal_create_app_stepper">
                                {/*begin::Aside*/}
                                <div className="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px">
                                    {/*begin::Nav*/}
                                    <div className="stepper-nav ps-lg-10">
                                        {/*begin::Step 1*/}
                                        <div className={`stepper-item ${isCurrent(1)}`}>
                                            {/*begin::Line*/}
                                            <div className="stepper-line w-40px"></div>
                                            {/*end::Line*/}
                                            {/*begin::Icon*/}
                                            <div className="stepper-icon w-40px h-40px">
                                                <i className="stepper-check fas fa-check"></i>
                                                <span className="stepper-number">1</span>
                                            </div>
                                            {/*end::Icon*/}
                                            {/*begin::Label*/}
                                            <div className="stepper-label">
                                                <h3 className="stepper-title">{t('--BoxRentModal.Box details')}</h3>
                                                {/* <div className="stepper-desc">{t('--BoxRentModal.Confirm box details')}</div> */}
                                            </div>
                                            {/*end::Label*/}
                                        </div>
                                        {/*end::Step 1*/}
                                        {/*begin::Step 2*/}
                                        <div className={`stepper-item ${isCurrent(2)}`}>
                                            {/*begin::Line*/}
                                            <div className="stepper-line w-40px"></div>
                                            {/*end::Line*/}
                                            {/*begin::Icon*/}
                                            <div className="stepper-icon w-40px h-40px">
                                                <i className="stepper-check fas fa-check"></i>
                                                <span className="stepper-number">2</span>
                                            </div>
                                            {/*begin::Icon*/}
                                            {/*begin::Label*/}
                                            <div className="stepper-label">
                                                <h3 className="stepper-title">{t('--BoxRentModal.Customer')}</h3>
                                                {/* <div className="stepper-desc">{t('--BoxRentModal.Select customer for ')}</div> */}
                                            </div>
                                            {/*begin::Label*/}
                                        </div>
                                        {/*end::Step 2*/}
                                        {/*begin::Step 3*/}
                                        <div className={`stepper-item ${isCurrent(3)}`}>
                                            {/*begin::Line*/}
                                            <div className="stepper-line w-40px"></div>
                                            {/*end::Line*/}
                                            {/*begin::Icon*/}
                                            <div className="stepper-icon w-40px h-40px">
                                                <i className="stepper-check fas fa-check"></i>
                                                <span className="stepper-number">3</span>
                                            </div>
                                            {/*end::Icon*/}
                                            {/*begin::Label*/}
                                            <div className="stepper-label">
                                                <h3 className="stepper-title">{t('--BoxRentModal.Rental period')}</h3>
                                                {/* <div className="stepper-desc">{t('--BoxRentModal.Select the dates of ')}</div> */}
                                            </div>
                                            {/*end::Label*/}
                                        </div>
                                        {/*end::Step 3*/}
                                        {/*begin::Step 4*/}
                                        <div className={`stepper-item ${isCurrent(4)}`}>
                                            {/*begin::Line*/}
                                            <div className="stepper-line w-40px"></div>
                                            {/*end::Line*/}
                                            {/*begin::Icon*/}
                                            <div className="stepper-icon w-40px h-40px">
                                                <i className="stepper-check fas fa-check"></i>
                                                <span className="stepper-number">4</span>
                                            </div>
                                            {/*end::Icon*/}
                                            {/*begin::Label*/}
                                            <div className="stepper-label">
                                                <h3 className="stepper-title">{t('--BoxRentModal.Select discounts')}</h3>
                                                {/* <div className="stepper-desc">{t('--BoxRentModal.Select discounts for this renting')}</div> */}
                                            </div>
                                            {/*end::Label*/}
                                        </div>
                                        {/*end::Step 4*/}
                                        {/*begin::Step 4*/}
                                        <div className={`stepper-item ${isCurrent(5)}`}>
                                            {/*begin::Line*/}
                                            <div className="stepper-line w-40px"></div>
                                            {/*end::Line*/}
                                            {/*begin::Icon*/}
                                            <div className="stepper-icon w-40px h-40px">
                                                <i className="stepper-check fas fa-check"></i>
                                                <span className="stepper-number">5</span>
                                            </div>
                                            {/*end::Icon*/}
                                            {/*begin::Label*/}
                                            <div className="stepper-label">
                                                <h3 className="stepper-title">{t('--BoxRentModal.Confirm & save')}</h3>
                                                {/* <div className="stepper-desc">{t('--BoxRentModal.Confirm the contract--')}</div> */}
                                            </div>
                                            {/*end::Label*/}
                                        </div>
                                        {/*end::Step 4*/}
                                        {/*begin::Step 5*/}
                                        <div className={`stepper-item ${isCurrent(6)}`}>
                                            {/*begin::Line*/}
                                            <div className="stepper-line w-40px"></div>
                                            {/*end::Line*/}
                                            {/*begin::Icon*/}
                                            <div className="stepper-icon w-40px h-40px">
                                                <i className="stepper-check fas fa-check"></i>
                                                <span className="stepper-number">6</span>
                                            </div>
                                            {/*end::Icon*/}
                                            {/*begin::Label*/}
                                            <div className="stepper-label">
                                                <h3 className="stepper-title">{t('--BoxRentModal.Sign the contract')}</h3>
                                                {/* <div className="stepper-desc">{t('--BoxRentModal.Sign the contract on')}</div> */}
                                            </div>
                                            {/*end::Label*/}
                                        </div>
                                        {/*end::Step 5*/}
                                        {/*begin::Step 5*/}
                                        <div className={`stepper-item ${isCurrent(7)}`}>
                                            {/*begin::Line*/}
                                            <div className="stepper-line w-40px"></div>
                                            {/*end::Line*/}
                                            {/*begin::Icon*/}
                                            <div className="stepper-icon w-40px h-40px">
                                                <i className="stepper-check fas fa-check"></i>
                                                <span className="stepper-number">7</span>
                                            </div>
                                            {/*end::Icon*/}
                                            {/*begin::Label*/}
                                            <div className="stepper-label">
                                                <h3 className="stepper-title">{t('--BoxRentModal.Finish')}</h3>
                                                {/* <div className="stepper-desc">{t('--BoxRentModal.We have a new contra')}</div> */}
                                            </div>
                                            {/*end::Label*/}
                                        </div>
                                        {/*end::Step 5*/}
                                    </div>
                                    {/*end::Nav*/}
                                </div>
                                {/*begin::Aside*/}
                                {/*begin::Content*/}
                                <div className="flex-row-fluid py-lg-0 px-lg-15">
                                    {/*begin::Form*/}
                                    <form className="form" onSubmit={handleSubmit}>
                                        {/*begin::Step 1*/}
                                        <div className={`${currentStep === 1 ? 'current' : ''}`} data-kt-stepper-element="content">
                                            <div className="w-100">
                                                {/*begin::Input group*/}
                                                <div className="fv-row">
                                                    <div className="card-body p-0">
                                                        <div className="mb-10 text-center">
                                                            {/*begin::Title*/}
                                                            <h1 className="mb-3">{t('--BoxRentModal.Box details')}</h1>
                                                            {/*end::Title*/}
                                                            {/*begin::Description*/}
                                                            <div className="text-gray-400 fw-bold fs-5">{t('--BoxRentModal.Review the box detai')}
                                                            </div>
                                                            {/*end::Description*/}
                                                        </div>
                                                        {/*begin::Row*/}
                                                        <div className="row mb-5">
                                                            {/*begin::Label*/}
                                                            <label className="col-lg-4 fw-bold text-muted">{t("--Common.Box")}</label>
                                                            {/*end::Label*/}
                                                            {/*begin::Col*/}
                                                            <div className="col-lg-8">
                                                                <span className="fw-bolder fs-6 text-dark">#{box.number}</span>
                                                            </div>
                                                            {/*end::Col*/}
                                                        </div>
                                                        {/*end::Row*/}
                                                        {/*begin::Input group*/}
                                                        <div className="row mb-5">
                                                            {/*begin::Label*/}
                                                            <label className="col-lg-4 fw-bold text-muted">{t("--Common.Size")}</label>
                                                            {/*end::Label*/}
                                                            {/*begin::Col*/}
                                                            <div className="col-lg-8 fv-row">
                                                                <span className="fw-bold fs-6 me-2">{box.area}m2</span>
                                                                <span className="badge badge-success">{box.sizeReadable}</span>
                                                            </div>
                                                            {/*end::Col*/}
                                                        </div>
                                                        {/*end::Input group*/}
                                                        {/*begin::Input group*/}
                                                        <div className="row mb-5">
                                                            {/*begin::Label*/}
                                                            <label className="col-lg-4 fw-bold text-muted">{t("--Common.Price")}</label>
                                                            {/*end::Label*/}
                                                            {/*begin::Col*/}
                                                            <div className="col-lg-8 d-flex align-items-center">
                                                                <span className="fw-bolder fs-6 me-2">{box.monthlyPrice}zł / {t("--Common.month")}</span>
                                                            </div>
                                                            {/*end::Col*/}
                                                        </div>
                                                        {/*end::Input group*/}
                                                        {/*begin::Notice*/}
                                                        {box.hasReservations &&
                                                            <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
                                                                {/*begin::Icon*/}
                                                                {/*begin::Svg Icon | path: icons/duotone/Code/Warning-1-circle.svg*/}
                                                                <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                        <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"></circle>
                                                                        <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1"></rect>
                                                                        <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1"></rect>
                                                                    </svg>
                                                                </span>
                                                                {/*end::Svg Icon*/}
                                                                {/*end::Icon*/}
                                                                {/*begin::Wrapper*/}

                                                                <div className="d-flex flex-stack flex-grow-1">
                                                                    {/*begin::Content*/}
                                                                    <div className="fw-bold">
                                                                        <h4 className="text-gray-800 fw-bolder">{t('--BoxReservationModal.This box has reserva')}</h4>
                                                                        <div className="fs-6 text-gray-600">{t('--BoxReservationModal.Please keep in mind ')}
                                                                        </div>
                                                                    </div>
                                                                    {/*end::Content*/}
                                                                </div>
                                                                {/*end::Wrapper*/}
                                                            </div>}
                                                        {/*end::Notice*/}
                                                    </div>
                                                </div>
                                                {/*end::Input group*/}
                                            </div>
                                        </div>
                                        {/*end::Step 1*/}
                                        {/*begin::Step 2*/}
                                        <div className={`${currentStep === 2 ? 'current' : ''}`} data-kt-stepper-element="content">
                                            <div className="w-100">
                                                {/*begin::Input group*/}
                                                <div className="fv-row">
                                                    <div className="mb-10 text-center">
                                                        {/*begin::Title*/}
                                                        <h1 className="mb-3">{t('--BoxRentModal.Customer')}</h1>
                                                        {/*end::Title*/}
                                                        {/*begin::Description*/}
                                                        <div className="text-gray-400 fw-bold fs-5">{t('--BoxRentModal.Select a customer fo')}
                                                            <NavLink to={urls.customersList()} className="fw-bolder link-primary"> {t('--Common.here')}</NavLink>
                                                        </div>
                                                        {/*end::Description*/}
                                                    </div>
                                                    {/*begin::Input*/}
                                                    <input type="text" onChange={handleSearchChange} className="form-control form-control-solid" placeholder={t('--Common.Search (3 letters mi')} />
                                                    {/*end::Input*/}
                                                </div>
                                                {/*end::Input group*/}
                                                {/*begin::Input group*/}
                                                <div className="mb-8">
                                                    {/*begin::Users*/}
                                                    <div className="mh-300px scroll-y me-n7 pe-7">
                                                        {/*begin::User*/}
                                                        {store.items.map((c) => (
                                                            <div key={c.id} className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed" onClick={() => handleSelectCustomer(c)}>
                                                                {/*begin::Details*/}
                                                                <div className="d-flex align-items-center">
                                                                    {/*begin::Details*/}
                                                                    <div className="ms-5">
                                                                        <span className="fs-5 fw-bolder text-gray-900 mb-2">{c.fullName}</span>
                                                                        <div className="fw-bold text-gray-400">{c.email}</div>
                                                                    </div>
                                                                    {/*end::Details*/}
                                                                </div>
                                                                {/*end::Details*/}
                                                                {/*begin::Access menu*/}
                                                                {customer?.id !== c.id &&
                                                                    <div className="ms-2 w-100px">
                                                                        <button className="btn btn-sm">
                                                                            <span className="svg-icon svg-icon-5 svg-icon-gray-500 me-1">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                        <polygon points="0 0 24 0 24 24 0 24" />
                                                                                        <path d="M9.26193932,16.6476484 C8.90425297,17.0684559 8.27315905,17.1196257 7.85235158,16.7619393 C7.43154411,16.404253 7.38037434,15.773159 7.73806068,15.3523516 L16.2380607,5.35235158 C16.6013618,4.92493855 17.2451015,4.87991302 17.6643638,5.25259068 L22.1643638,9.25259068 C22.5771466,9.6195087 22.6143273,10.2515811 22.2474093,10.6643638 C21.8804913,11.0771466 21.2484189,11.1143273 20.8356362,10.7474093 L17.0997854,7.42665306 L9.26193932,16.6476484 Z" fill="#000000" fillRule="nonzero" opacity="0.5" transform="translate(14.999995, 11.000002) rotate(-180.000000) translate(-14.999995, -11.000002) " />
                                                                                        <path d="M4.26193932,17.6476484 C3.90425297,18.0684559 3.27315905,18.1196257 2.85235158,17.7619393 C2.43154411,17.404253 2.38037434,16.773159 2.73806068,16.3523516 L11.2380607,6.35235158 C11.6013618,5.92493855 12.2451015,5.87991302 12.6643638,6.25259068 L17.1643638,10.2525907 C17.5771466,10.6195087 17.6143273,11.2515811 17.2474093,11.6643638 C16.8804913,12.0771466 16.2484189,12.1143273 15.8356362,11.7474093 L12.0997854,8.42665306 L4.26193932,17.6476484 Z" fill="#000000" fillRule="nonzero" transform="translate(9.999995, 12.000002) rotate(-180.000000) translate(-9.999995, -12.000002) " />
                                                                                    </g>
                                                                                </svg>
                                                                            </span>
                                                                        </button>
                                                                    </div>}
                                                                {customer?.id === c.id &&
                                                                    <div className="ms-2 w-100px">
                                                                        <button disabled className="btn btn-sm btn-primary">
                                                                            <span className="svg-icon svg-icon-5 svg-icon-gray-500 me-1">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                        <polygon points="0 0 24 0 24 24 0 24" />
                                                                                        <path d="M9.26193932,16.6476484 C8.90425297,17.0684559 8.27315905,17.1196257 7.85235158,16.7619393 C7.43154411,16.404253 7.38037434,15.773159 7.73806068,15.3523516 L16.2380607,5.35235158 C16.6013618,4.92493855 17.2451015,4.87991302 17.6643638,5.25259068 L22.1643638,9.25259068 C22.5771466,9.6195087 22.6143273,10.2515811 22.2474093,10.6643638 C21.8804913,11.0771466 21.2484189,11.1143273 20.8356362,10.7474093 L17.0997854,7.42665306 L9.26193932,16.6476484 Z" fill="#000000" fillRule="nonzero" opacity="0.5" transform="translate(14.999995, 11.000002) rotate(-180.000000) translate(-14.999995, -11.000002) " />
                                                                                        <path d="M4.26193932,17.6476484 C3.90425297,18.0684559 3.27315905,18.1196257 2.85235158,17.7619393 C2.43154411,17.404253 2.38037434,16.773159 2.73806068,16.3523516 L11.2380607,6.35235158 C11.6013618,5.92493855 12.2451015,5.87991302 12.6643638,6.25259068 L17.1643638,10.2525907 C17.5771466,10.6195087 17.6143273,11.2515811 17.2474093,11.6643638 C16.8804913,12.0771466 16.2484189,12.1143273 15.8356362,11.7474093 L12.0997854,8.42665306 L4.26193932,17.6476484 Z" fill="#000000" fillRule="nonzero" transform="translate(9.999995, 12.000002) rotate(-180.000000) translate(-9.999995, -12.000002) " />
                                                                                    </g>
                                                                                </svg>
                                                                            </span>
                                                                        </button>
                                                                    </div>}
                                                                {/*end::Access menu*/}
                                                            </div>
                                                        ))}

                                                        {/*end::User*/}

                                                    </div>
                                                    {/*end::Users*/}
                                                </div>
                                                {/*end::Input group*/}
                                            </div>
                                        </div>
                                        {/*end::Step 2*/}
                                        {/*begin::Step 3*/}
                                        <div className={`${currentStep === 3 ? 'current' : ''}`} data-kt-stepper-element="content">
                                            <div className="w-100">
                                                {/*begin::Input group*/}
                                                <div className="fv-row">
                                                    <div className="mb-10 text-center">
                                                        {/*begin::Title*/}
                                                        <h1 className="mb-3">{t('--BoxRentModal.Rental period')}</h1>
                                                        {/*end::Title*/}
                                                        {/*begin::Description*/}
                                                        <div className="text-gray-400 fw-bold fs-5">{t('--BoxRentModal.Select dates that co')}
                                                        </div>
                                                        {/*end::Description*/}
                                                    </div>
                                                </div>
                                                {/*end::Input group*/}
                                                <div className="row g-9 mb-8">
                                                    {/*begin::Col*/}
                                                    <div className="col-md-6 fv-row">
                                                        <label className="required fs-6 fw-bold mb-2">{t('--BoxReservationModal.Start Date')}</label>
                                                        {/*begin::Input*/}
                                                        <div className="position-relative d-flex align-items-center">
                                                            {/*begin::Icon*/}
                                                            <div className="symbol symbol-20px me-4 position-absolute ms-4">
                                                                <span className="symbol-label bg-secondary">
                                                                    {/*begin::Svg Icon | path: icons/duotone/Layout/Layout-grid.svg*/}
                                                                    <span className="svg-icon">
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                <rect x="0" y="0" width="24" height="24" />
                                                                                <rect fill="#000000" opacity="0.3" x="4" y="4" width="4" height="4" rx="1" />
                                                                                <path d="M5,10 L7,10 C7.55228475,10 8,10.4477153 8,11 L8,13 C8,13.5522847 7.55228475,14 7,14 L5,14 C4.44771525,14 4,13.5522847 4,13 L4,11 C4,10.4477153 4.44771525,10 5,10 Z M11,4 L13,4 C13.5522847,4 14,4.44771525 14,5 L14,7 C14,7.55228475 13.5522847,8 13,8 L11,8 C10.4477153,8 10,7.55228475 10,7 L10,5 C10,4.44771525 10.4477153,4 11,4 Z M11,10 L13,10 C13.5522847,10 14,10.4477153 14,11 L14,13 C14,13.5522847 13.5522847,14 13,14 L11,14 C10.4477153,14 10,13.5522847 10,13 L10,11 C10,10.4477153 10.4477153,10 11,10 Z M17,4 L19,4 C19.5522847,4 20,4.44771525 20,5 L20,7 C20,7.55228475 19.5522847,8 19,8 L17,8 C16.4477153,8 16,7.55228475 16,7 L16,5 C16,4.44771525 16.4477153,4 17,4 Z M17,10 L19,10 C19.5522847,10 20,10.4477153 20,11 L20,13 C20,13.5522847 19.5522847,14 19,14 L17,14 C16.4477153,14 16,13.5522847 16,13 L16,11 C16,10.4477153 16.4477153,10 17,10 Z M5,16 L7,16 C7.55228475,16 8,16.4477153 8,17 L8,19 C8,19.5522847 7.55228475,20 7,20 L5,20 C4.44771525,20 4,19.5522847 4,19 L4,17 C4,16.4477153 4.44771525,16 5,16 Z M11,16 L13,16 C13.5522847,16 14,16.4477153 14,17 L14,19 C14,19.5522847 13.5522847,20 13,20 L11,20 C10.4477153,20 10,19.5522847 10,19 L10,17 C10,16.4477153 10.4477153,16 11,16 Z M17,16 L19,16 C19.5522847,16 20,16.4477153 20,17 L20,19 C20,19.5522847 19.5522847,20 19,20 L17,20 C16.4477153,20 16,19.5522847 16,19 L16,17 C16,16.4477153 16.4477153,16 17,16 Z" fill="#000000" />
                                                                            </g>
                                                                        </svg>
                                                                    </span>
                                                                    {/*end::Svg Icon*/}
                                                                </span>
                                                            </div>
                                                            {/*end::Icon*/}
                                                            {/*begin::Datepicker*/}
                                                            {/* <input onChange={()=>alert('to')} className="form-control form-control-solid ps-12" placeholder="Select a date" name="contract_start_date" /> */}
                                                            <Flatpickr className="form-control form-control-solid ps-12" onChange={date => handleFromChange(date[0])} />
                                                            {/*end::Datepicker*/}
                                                        </div>
                                                        {/*end::Input*/}
                                                    </div>
                                                    {/*end::Col*/}
                                                    {/*begin::Col*/}
                                                    <div className="col-md-6 fv-row">
                                                        <label className="required fs-6 fw-bold mb-2">Ilość miesięcy</label>
                                                        {/*begin::Input*/}
                                                        <input className="form-control form-control-solid" type="number" min="1" name="quantity" placeholder="1" defaultValue="1" onChange={v => handleHowManyMonthsChange(v)}></input>
                                                        {/*end::Input*/}
                                                    </div>
                                                    {/*end::Col*/}
                                                    {from && to && !datesAreOk &&
                                                        <div className="col-12">
                                                            <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">
                                                                {/*begin::Icon*/}
                                                                {/*begin::Svg Icon | path: icons/duotone/Code/Warning-1-circle.svg*/}
                                                                <span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                        <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"></circle>
                                                                        <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1"></rect>
                                                                        <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1"></rect>
                                                                    </svg>
                                                                </span>
                                                                {/*end::Svg Icon*/}
                                                                {/*end::Icon*/}
                                                                {/*begin::Wrapper*/}

                                                                <div className="d-flex flex-stack flex-grow-1">
                                                                    {/*begin::Content*/}
                                                                    <div className="fw-bold">
                                                                        <h4 className="text-gray-800 fw-bolder">Daty niepoprawne!</h4>
                                                                        <div className="fs-6 text-gray-600">Wybrane daty konfliktują z już podpisanymi kontraktami lub rezerwacjami dla tego boxa
                                                                        </div>
                                                                    </div>
                                                                    {/*end::Content*/}
                                                                </div>
                                                                {/*end::Wrapper*/}
                                                            </div>
                                                        </div>}
                                                    {/*end::Notice*/}
                                                </div>
                                                {/*end::Input group*/}
                                            </div>
                                        </div>
                                        {/*end::Step 3*/}
                                        {/*begin::Step 2*/}
                                        <div className={`${currentStep === 4 ? 'current' : ''}`} data-kt-stepper-element="content">
                                            <div className="w-100">
                                                {/*begin::Input group*/}
                                                <div className="fv-row">
                                                    <div className="mb-10 text-center">
                                                        {/*begin::Title*/}
                                                        <h1 className="mb-3">{t('--BoxRentModal.Select discounts')}</h1>
                                                        {/*end::Title*/}
                                                        {/*begin::Description*/}
                                                        <div className="text-gray-400 fw-bold fs-5">{t('--BoxRentModal.Select discounts for this renting')}
                                                        </div>
                                                        {/*end::Description*/}
                                                    </div>
                                                    {/*begin::Input*/}
                                                    {/* <input type="text" onChange={handleSearchChange} className="form-control form-control-solid" placeholder="Search..." /> */}
                                                    {/*end::Input*/}
                                                </div>
                                                {/*end::Input group*/}
                                                {/*begin::Input group*/}
                                                <div className="mb-8">
                                                    {/*begin::Users*/}
                                                    <div className="mh-300px scroll-y me-n7 pe-7">
                                                        {/*begin::User*/}
                                                        {discountPlansStore.activeItems.map((c) => (
                                                            <div key={c.id} className="d-flex flex-stack py-4 border-bottom border-gray-300 border-bottom-dashed" onClick={() => handleToggleDiscountPlan(c)}>
                                                                {/*begin::Details*/}
                                                                <div className="d-flex align-items-center">
                                                                    {/*begin::Details*/}
                                                                    <div className="ms-5">
                                                                        <span className="fs-5 fw-bolder text-gray-900 mb-2">{c.name}</span>
                                                                        {/* <div className="fw-bold text-gray-400">{c.name}</div> */}
                                                                    </div>
                                                                    {/*end::Details*/}
                                                                </div>
                                                                {/*end::Details*/}
                                                                {/*begin::Access menu*/}
                                                                {!discountPlans.includes(c) &&
                                                                    <div className="ms-2 w-100px">
                                                                        <button type="button" className="btn btn-sm">
                                                                            <span className="svg-icon svg-icon-5 svg-icon-gray-500 me-1">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                        <polygon points="0 0 24 0 24 24 0 24" />
                                                                                        <path d="M9.26193932,16.6476484 C8.90425297,17.0684559 8.27315905,17.1196257 7.85235158,16.7619393 C7.43154411,16.404253 7.38037434,15.773159 7.73806068,15.3523516 L16.2380607,5.35235158 C16.6013618,4.92493855 17.2451015,4.87991302 17.6643638,5.25259068 L22.1643638,9.25259068 C22.5771466,9.6195087 22.6143273,10.2515811 22.2474093,10.6643638 C21.8804913,11.0771466 21.2484189,11.1143273 20.8356362,10.7474093 L17.0997854,7.42665306 L9.26193932,16.6476484 Z" fill="#000000" fillRule="nonzero" opacity="0.5" transform="translate(14.999995, 11.000002) rotate(-180.000000) translate(-14.999995, -11.000002) " />
                                                                                        <path d="M4.26193932,17.6476484 C3.90425297,18.0684559 3.27315905,18.1196257 2.85235158,17.7619393 C2.43154411,17.404253 2.38037434,16.773159 2.73806068,16.3523516 L11.2380607,6.35235158 C11.6013618,5.92493855 12.2451015,5.87991302 12.6643638,6.25259068 L17.1643638,10.2525907 C17.5771466,10.6195087 17.6143273,11.2515811 17.2474093,11.6643638 C16.8804913,12.0771466 16.2484189,12.1143273 15.8356362,11.7474093 L12.0997854,8.42665306 L4.26193932,17.6476484 Z" fill="#000000" fillRule="nonzero" transform="translate(9.999995, 12.000002) rotate(-180.000000) translate(-9.999995, -12.000002) " />
                                                                                    </g>
                                                                                </svg>
                                                                            </span>
                                                                        </button>
                                                                    </div>}
                                                                {discountPlans.includes(c) &&
                                                                    <div className="ms-2 w-100px">
                                                                        <button type="button" className="btn btn-sm btn-primary">
                                                                            <span className="svg-icon svg-icon-5 svg-icon-gray-500 me-1">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                        <polygon points="0 0 24 0 24 24 0 24" />
                                                                                        <path d="M9.26193932,16.6476484 C8.90425297,17.0684559 8.27315905,17.1196257 7.85235158,16.7619393 C7.43154411,16.404253 7.38037434,15.773159 7.73806068,15.3523516 L16.2380607,5.35235158 C16.6013618,4.92493855 17.2451015,4.87991302 17.6643638,5.25259068 L22.1643638,9.25259068 C22.5771466,9.6195087 22.6143273,10.2515811 22.2474093,10.6643638 C21.8804913,11.0771466 21.2484189,11.1143273 20.8356362,10.7474093 L17.0997854,7.42665306 L9.26193932,16.6476484 Z" fill="#000000" fillRule="nonzero" opacity="0.5" transform="translate(14.999995, 11.000002) rotate(-180.000000) translate(-14.999995, -11.000002) " />
                                                                                        <path d="M4.26193932,17.6476484 C3.90425297,18.0684559 3.27315905,18.1196257 2.85235158,17.7619393 C2.43154411,17.404253 2.38037434,16.773159 2.73806068,16.3523516 L11.2380607,6.35235158 C11.6013618,5.92493855 12.2451015,5.87991302 12.6643638,6.25259068 L17.1643638,10.2525907 C17.5771466,10.6195087 17.6143273,11.2515811 17.2474093,11.6643638 C16.8804913,12.0771466 16.2484189,12.1143273 15.8356362,11.7474093 L12.0997854,8.42665306 L4.26193932,17.6476484 Z" fill="#000000" fillRule="nonzero" transform="translate(9.999995, 12.000002) rotate(-180.000000) translate(-9.999995, -12.000002) " />
                                                                                    </g>
                                                                                </svg>
                                                                            </span>
                                                                        </button>
                                                                    </div>}
                                                                {/*end::Access menu*/}
                                                            </div>
                                                        ))}

                                                        {/*end::User*/}

                                                    </div>
                                                    {/*end::Users*/}
                                                </div>
                                                {/*end::Input group*/}
                                            </div>
                                        </div>
                                        {/*end::Step 2*/}
                                        {/*begin::Step 4*/}
                                        <div className={`${currentStep === 5 ? 'current' : ''}`} data-kt-stepper-element="content">
                                            <div className="w-100">
                                                <div className="fv-row">
                                                    <div className="mb-10 text-center">
                                                        {/*begin::Title*/}
                                                        <h1 className="mb-3">{t('--BoxRentModal.Confirm & save')}</h1>
                                                        {/*end::Title*/}
                                                        {/*begin::Description*/}
                                                        <div className="text-gray-400 fw-bold fs-5">{t('--BoxRentModal.Confirm the contract')}
                                                        </div>
                                                        {/*end::Description*/}
                                                    </div>
                                                </div>
                                                <div className="fv-row">
                                                    {/*begin::Row*/}
                                                    <div className="row mb-5">
                                                        {/*begin::Label*/}
                                                        <label className="col-lg-4 fw-bold text-muted">Box</label>
                                                        {/*end::Label*/}
                                                        {/*begin::Col*/}
                                                        <div className="col-lg-8">
                                                            <span className="fw-bolder fs-6 text-dark">#{box.number}, {box.area}m2, {box.monthlyPrice}zł/month</span>
                                                        </div>
                                                        {/*end::Col*/}
                                                    </div>
                                                    {/*end::Row*/}
                                                    {/*begin::Input group*/}
                                                    <div className="row mb-5">
                                                        {/*begin::Label*/}
                                                        <label className="col-lg-4 fw-bold text-muted">{t("--BoxRentModal.Customer")}</label>
                                                        {/*end::Label*/}
                                                        {/*begin::Col*/}
                                                        <div className="col-lg-8 fv-row">
                                                            <span className="fw-bolder fs-6 text-dark">{customer?.fullName}, {customer?.phone}</span>
                                                        </div>
                                                        {/*end::Col*/}
                                                    </div>
                                                    {/*end::Input group*/}
                                                    {/*begin::Input group*/}
                                                    <div className="row mb-5">
                                                        {/*begin::Label*/}
                                                        <label className="col-lg-4 fw-bold text-muted">{t("--BoxRentModal.Rental period")}</label>
                                                        {/*end::Label*/}
                                                        {/*begin::Col*/}
                                                        <div className="col-lg-8 d-flex align-items-center">
                                                            <span className="fw-bolder fs-6 me-2">{formatDate(from)} - {formatDate(to)}</span>
                                                        </div>
                                                        {/*end::Col*/}
                                                    </div>
                                                    <div className="row mb-5">
                                                        {/*begin::Label*/}
                                                        <label className="col-lg-4 fw-bold text-muted">{t("--Navigation.Discount plans")}</label>
                                                        {/*end::Label*/}
                                                        {/*begin::Col*/}
                                                        <div className="col-lg-8">
                                                            {discountPlans.map(dp => (
                                                                <div key={dp.id} >
                                                                    <span className="fw-bolder fs-6 me-2">{dp.name}</span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        {/*end::Col*/}
                                                    </div>
                                                    {/*end::Input group*/}
                                                </div>
                                            </div>
                                        </div>
                                        {/*end::Step 4*/}
                                        {/*begin::Step 5*/}
                                        <div className={`${currentStep === 6 ? 'current' : ''}`} data-kt-stepper-element="content">
                                            <div className="w-100">
                                                <div className="fv-row">
                                                    <div className="mb-10 text-center">
                                                        {/*begin::Title*/}
                                                        <h1 className="mb-3">{t('--BoxRentModal.Sign contract and su')}</h1>
                                                        {/*end::Title*/}
                                                        {/*begin::Description*/}
                                                        <div className="text-gray-400 fw-bold fs-5">{t('--BoxRentModal.1. Please click prin')}
                                                        </div>
                                                        <div className="text-gray-400 fw-bold fs-5">{t('--BoxRentModal.2. Sign the contract')}
                                                        </div>
                                                        <div className="text-gray-400 fw-bold fs-5">{t('--BoxRentModal.3. Click continiu to')}
                                                        </div>
                                                        {/*end::Description*/}
                                                    </div>
                                                </div>
                                                <div className="row mb-5">
                                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed h-lg-100 p-6">
                                                        <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect opacity="0.25" x="3" y="21" width="18" height="2" rx="1" fill="#191213" />
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M4.08576 11.5L3.58579 12C3.21071 12.375 3 12.8838 3 13.4142V17C3 18.1045 3.89543 19 5 19H8.58579C9.11622 19 9.62493 18.7893 10 18.4142L10.5 17.9142L4.08576 11.5Z" fill="#121319" />
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M5.5 10.0858L11.5858 4L18 10.4142L11.9142 16.5L5.5 10.0858Z" fill="#121319" />
                                                                <path opacity="0.25" fillRule="evenodd" clipRule="evenodd" d="M18.1214 1.70705C16.9498 0.535476 15.0503 0.535476 13.8787 1.70705L13 2.58576L19.4142 8.99997L20.2929 8.12126C21.4645 6.94969 21.4645 5.0502 20.2929 3.87862L18.1214 1.70705Z" fill="#191213" />
                                                            </svg>
                                                        </span>
                                                        {/*begin::Wrapper*/}
                                                        <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                                                            {/*begin::Content*/}
                                                            <div className="mb-3 mb-md-0 fw-bold">
                                                                <h4 className="text-gray-800 fw-bolder">{t('--BoxRentModal.Sign the contract!')}</h4>
                                                                <div className="fs-6 text-gray-600 pe-7">
                                                                    {t('--BoxRentModal.Click the button on ')}
                                                                </div>
                                                            </div>
                                                            {/*end::Content*/}
                                                            {/*begin::Action*/}
                                                            <a href={`${process.env.REACT_APP_API_URL}/documentprint/contract/${contractId}`} target="blank"
                                                                className="btn btn-primary px-6 align-self-center text-nowrap">{t("--Common.Sign")}</a>
                                                            {/*end::Action*/}
                                                        </div>
                                                        {/*end::Wrapper*/}
                                                    </div>
                                                </div>
                                                {/* <div className="fv-row mb-5">
                                                    <PhotoWidgetDropzone setFiles={setFiles} />
                                                </div> */}
                                            </div>
                                        </div>
                                        {/*end::Step 5*/}
                                        {/*begin::Step 6*/}
                                        <div className={`${currentStep === 7 ? 'current' : ''}`} data-kt-stepper-element="content">
                                            <div className="w-100">
                                                <div className="fv-row">
                                                    <div className="mb-10 text-center">
                                                        {/*begin::Title*/}
                                                        <h1 className="mb-3">{t('--BoxRentModal.Finish')}</h1>
                                                        {/*end::Title*/}
                                                        {/*begin::Description*/}
                                                        <div className="text-gray-400 fw-bold fs-5">{t('--BoxRentModal.Final step of the pr')}
                                                        </div>
                                                        {/*end::Description*/}
                                                    </div>
                                                </div>
                                                <div className="row mb-5">
                                                    <div className="notice d-flex bg-light-success rounded border-success border border-dashed h-lg-100 p-6">
                                                        <span className="svg-icon svg-icon-2tx svg-icon-success me-4">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect opacity="0.25" x="3" y="21" width="18" height="2" rx="1" fill="#191213" />
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M4.08576 11.5L3.58579 12C3.21071 12.375 3 12.8838 3 13.4142V17C3 18.1045 3.89543 19 5 19H8.58579C9.11622 19 9.62493 18.7893 10 18.4142L10.5 17.9142L4.08576 11.5Z" fill="#121319" />
                                                                <path fillRule="evenodd" clipRule="evenodd" d="M5.5 10.0858L11.5858 4L18 10.4142L11.9142 16.5L5.5 10.0858Z" fill="#121319" />
                                                                <path opacity="0.25" fillRule="evenodd" clipRule="evenodd" d="M18.1214 1.70705C16.9498 0.535476 15.0503 0.535476 13.8787 1.70705L13 2.58576L19.4142 8.99997L20.2929 8.12126C21.4645 6.94969 21.4645 5.0502 20.2929 3.87862L18.1214 1.70705Z" fill="#191213" />
                                                            </svg>
                                                        </span>
                                                        {/*begin::Wrapper*/}
                                                        <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                                                            {/*begin::Content*/}
                                                            <div className="mb-3 mb-md-0 fw-bold">
                                                                <h4 className="text-gray-800 fw-bolder">{t('--BoxRentModal.The contract has bee')}</h4>
                                                                <div className="fs-6 text-gray-600 pe-7">
                                                                    {t('--BoxRentModal.Everything went fine')}
                                                                </div>
                                                            </div>
                                                            {/*end::Content*/}
                                                            {/*begin::Action*/}
                                                            <NavLink to={urls.contractDetails(contractId)}
                                                                className="btn btn-success px-6 align-self-center text-nowrap" >{t('--BoxRentModal.See my contract')}</NavLink>
                                                            {/*end::Action*/}
                                                        </div>
                                                        {/*end::Wrapper*/}
                                                    </div>
                                                </div>
                                                {/* <div className="fv-row mb-5">
                                                    <PhotoWidgetDropzone setFiles={setFiles} />
                                                </div> */}
                                            </div>
                                        </div>
                                        {/*end::Step 6*/}
                                        {/*begin::Actions*/}
                                        <div className="d-flex flex-stack pt-5">
                                            {/*begin::Wrapper*/}
                                            <div className="me-2">
                                                {currentStep !== 1 && currentStep! !== numberOfSteps && currentStep <= saveStep &&
                                                    <button type="button" onClick={() => setCurrentStep(currentStep - 1)} className="btn btn-lg btn-light-primary me-3">
                                                        {/*begin::Svg Icon | path: icons/duotone/Navigation/Left-2.svg*/}
                                                        <span className="svg-icon svg-icon-3 me-1">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                    <polygon points="0 0 24 0 24 24 0 24" />
                                                                    <rect fill="#000000" opacity="0.3" transform="translate(15.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-15.000000, -12.000000)" x="14" y="7" width="2" height="10" rx="1" />
                                                                    <path d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997)" />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        {/*end::Svg Icon*/}{t("--Common.Back")}</button>}
                                            </div>
                                            {/*end::Wrapper*/}
                                            {/*begin::Wrapper*/}
                                            <div>
                                                {currentStep === numberOfSteps &&
                                                    <button onClick={() => handleSubmit()} className="btn btn-lg btn-success">
                                                        <span className="indicator-label">{t("--Common.Close")}
                                                            {/*begin::Svg Icon | path: icons/duotone/Navigation/Right-2.svg*/}
                                                            <span className="svg-icon svg-icon-3 ms-2 me-0">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                        <polygon points="0 0 24 0 24 24 0 24" />
                                                                        <path d="M6.26193932,17.6476484 C5.90425297,18.0684559 5.27315905,18.1196257 4.85235158,17.7619393 C4.43154411,17.404253 4.38037434,16.773159 4.73806068,16.3523516 L13.2380607,6.35235158 C13.6013618,5.92493855 14.2451015,5.87991302 14.6643638,6.25259068 L19.1643638,10.2525907 C19.5771466,10.6195087 19.6143273,11.2515811 19.2474093,11.6643638 C18.8804913,12.0771466 18.2484189,12.1143273 17.8356362,11.7474093 L14.0997854,8.42665306 L6.26193932,17.6476484 Z" fill="#000000" fillRule="nonzero" transform="translate(11.999995, 12.000002) rotate(-180.000000) translate(-11.999995, -12.000002) " />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                            {/*end::Svg Icon*/}</span>
                                                    </button>}
                                                {currentStep !== numberOfSteps &&
                                                    <button type="button" onClick={() => handleContinue()} className="btn btn-lg btn-primary" disabled={!continueAvailable()}>{t("--Common.Continue")}
                                                        {/*begin::Svg Icon | path: icons/duotone/Navigation/Right-2.svg*/}
                                                        <span className="svg-icon svg-icon-3 ms-1 me-0">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                    <polygon points="0 0 24 0 24 24 0 24" />
                                                                    <rect fill="#000000" opacity="0.5" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width="2" height="9" rx="1" />
                                                                    <path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        {/*end::Svg Icon*/}</button>}
                                            </div>
                                            {/*end::Wrapper*/}
                                        </div>
                                        {/*end::Actions*/}
                                    </form>
                                    {/*end::Form*/}
                                </div>
                                {/*end::Content*/}
                            </div>
                            {/*end::Stepper*/}
                        </div>
                        {/*end::Modal body*/}
                    </div>
                    {/*end::Modal content*/}
                </div>
                {/*end::Modal dialog*/}
            </div>
            {/*end::Modal - Create App*/}
            <div className="modal-backdrop fade show"></div>
        </>
    )
})
