import React from 'react';
import { observer } from 'mobx-react-lite';
import ChangePassword from './ChangePassword';
import { useHistory, useParams } from 'react-router-dom';
import urls from '../../app/common/urls';

export default observer(function ResetPasswordPage() {
    const { token } = useParams<{ token: string }>();
    const { uid } = useParams<{ uid: string }>();
    const history = useHistory();

    const handleClose = () => {
        history.push(urls.root());
    }

    return (
        <>
            <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed" style={{ backgroundImage: "url(assets/media/illustrations/progress-hd.png)" }}>
                {/*begin::Content*/}
                <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
                    {/*begin::Logo*/}
                    <a href="index.html" className="mb-12">
                        <img alt="Logo" src="custom/logo_large.png" className="h-90px" />
                    </a>
                    {/*end::Logo*/}
                    {/*begin::Wrapper*/}
                    <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
                        {/*begin::Form*/}
                        <ChangePassword OnClose={handleClose} requireOldPassword={false} token={token} uid={uid} />
                        {/*end::Form*/}
                    </div>
                    {/*end::Wrapper*/}
                </div>
                {/*end::Content*/}
                {/*begin::Footer*/}
                <div className="d-flex flex-center flex-column-auto p-10">
                    {/*begin::Links*/}
                    <div className="d-flex align-items-center fw-bold fs-6">
                        <span>Powered by Cando Solutions</span>
                    </div>
                    {/*end::Links*/}
                </div>
                {/*end::Footer*/}
            </div>
        </>
    )
})