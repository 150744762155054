import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../../app/stores/store';
import { useEffect } from 'react';
import { IconBoxes, IconCustomers, IconReservations } from '../../../app/layout/icons/Icons';
import { useTranslation } from 'react-i18next';

export default observer(function BoxesWidgets() {
    const { t } = useTranslation('common');

    const { statisticsStore } = useStore();
    const { generalForBoxesStatistics } = statisticsStore;
    useEffect(() => {
        statisticsStore.loadStatistics();
    }, [statisticsStore, statisticsStore.loadStatistics])

    return (
        <>
            <div className="row g-5 g-xl-8">
                <div className="col-xl-3">
                    <div className="card bg-success card-xl-stretch mb-xl-8">
                        <div className="card-body">
                            <a href="/" className="card-title fw-bolder text-white fs-5 mb-3 d-block">{t('--BoxesList.Area rented')}</a>
                            <div className="py-1">
                                <span className="text-white fs-1 fw-bolder me-2">{generalForBoxesStatistics?.percentOfAreaRented}%</span>
                                <span className="fw-bold text-white fs-7">of {generalForBoxesStatistics?.totalArea}m2</span>
                            </div>
                            <div className="progress h-7px bg-white bg-opacity-50 mt-7">
                                <div className="progress-bar bg-white" role="progressbar" style={{ width: `${generalForBoxesStatistics?.percentOfAreaRented}%` }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}></div>
                            </div>
                        </div>
                    </div>
                </div><div className="col-xl-3">
                    <div className="card bg-primary card-xl-stretch mb-xl-8">
                        <div className="card-body">
                            <span className="svg-icon svg-icon-white svg-icon-3x ms-n1">
                                <IconBoxes size="3" />
                            </span>
                            <div className="text-inverse-dark fw-bolder fs-2 mb-2 mt-5">{generalForBoxesStatistics?.boxesRented}</div>
                            <div className="fw-bold text-inverse-dark fs-7">{t('--BoxesList.Boxes rented')}</div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3">
                    <div className="card bg-warning card-xl-stretch mb-xl-8">
                        <div className="card-body">
                            <span className="svg-icon svg-icon-white svg-icon-3x ms-n1">
                                <IconCustomers size="3" />
                            </span>
                            <div className="text-inverse-warning fw-bolder fs-2 mb-2 mt-5">{generalForBoxesStatistics?.activeCustomers}</div>
                            <div className="fw-bold text-inverse-warning fs-7">{t('--BoxesList.Active customers')}</div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-3">
                    <div className="card bg-primary card-xl-stretch mb-xl-8">
                        <div className="card-body">
                            <span className="svg-icon svg-icon-white svg-icon-3x ms-n1">
                                <IconReservations size="3" />
                            </span>
                            <div className="text-white fw-bolder fs-2 mb-2 mt-5">{generalForBoxesStatistics?.reservations}</div>
                            <div className="fw-bold text-white fs-7">{t('--BoxesList.Reservations')}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})