import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BoxCalendar } from '../../../app/models/models';

interface Props {
    boxCalendar: BoxCalendar
}

export default observer(function BoxCalendar({ boxCalendar }: Props) {
    const [t] = useTranslation('common');

    const statusToClass = (status: string) => {
        switch (status){
            case "2": return "rented";
            case "1": return "reserved";
        }
        return "";
    }

    return (
        <>
            {/*begin::Mixed Widget 6*/}
            <div className="card card-xl-stretch mt-7 mb-xl-8 pt-9">
                {/*begin::Beader*/}
                <div className="card-header border-0">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder fs-2 mb-2">{t('--BoxDetails.Box Calendar')}</span>
                        <span className="text-muted fw-bold fs-7">{t('--BoxDetails.Next 12 months for this box')}</span>
                    </h3>
                    <div className="card-toolbar">

                    </div>
                </div>
                {/*end::Header*/}
                {/*begin::Body*/}
                <div className="card-body p-0 d-flex flex-column">
                    {/*begin::Stats*/}
                    <div className="card-px pt-5 pb-10 flex-grow-1">

                        {/*begin::Row*/}
                        <div className="row g-0 mt-5 mb-10">
                            {/*begin::Col*/}
                            <div className="col">
                                <table className="box-calendar text-gray-600">
                                    <tbody>
                                        {boxCalendar.months.map(bm => (<tr key={bm.monthYear}>
                                            <td className="month-name">{bm.monthYear}</td>
                                            {bm.days.map((day, index) => (<td key={bm.monthYear + index} className={statusToClass(day)}>{index + 1}</td>))}
                                        </tr>))}
                                    </tbody>
                                </table>
                            </div>
                            {/*end::Col*/}
                        </div>
                        {/*end::Row*/}
                    </div>
                    {/*end::Stats*/}
                </div>
                {/*end::Body*/}
            </div>
            {/*end::Mixed Widget 6*/}
        </>
    )
})
