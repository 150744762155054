import { observer } from 'mobx-react-lite';
import React from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useStore } from '../../../app/stores/store';
import ContractData from './ContractData';
import ContractInvoices from './ContractInvoices';

export default observer(function ContractPage() {
    var { contractStore, invoiceStore } = useStore();
    const { id } = useParams<{ id: string }>();
    const { selectedItem } = contractStore;

    useEffect(() => {
        contractStore.loadItem(id);
        invoiceStore.filteringAndPaginationController.clearPredicateAndPaging();
        invoiceStore.filteringAndPaginationController.setPredicate('contractId', id);
    }, [
        id,
        contractStore.loadItem,
        contractStore, contractStore.filteringAndPaginationController.setPredicate,
        invoiceStore, invoiceStore.filteringAndPaginationController.setPredicate])

    return (
        <>
            {selectedItem &&
                <div id="kt_content_container" className="container">
                    <div className="row">
                        <div className="col-4">
                            <ContractData contract={selectedItem} />
                            {/* <ContractStatistics contract={selectedItem} /> */}
                        </div>
                        <div className="col-8">
                            <ContractInvoices />
                        </div>
                    </div>
                </div>}
        </>
    )
})
