import React from 'react';
import { observer } from 'mobx-react-lite';
import { Field, useField } from 'formik';
import { useTranslation } from 'react-i18next';

interface Props {
    name: string,
    label?: string
    required?: boolean
    type?: string
    className?: string
}

export default observer(function TextInput(props: Props) {
    const [t] = useTranslation('common');
    const [field, meta] = useField(props.name);

    return (
        <>
            {props.label &&
                <label className="d-flex align-items-center fs-6 fw-bold mb-2">
                    <span className={`${props.required ? 'required' : ''}`}>{props.label}</span>
                </label>}
            <Field  {...field} {...props} className="form-control form-control-solid"  placeholder={`${t("--Common.Enter")}: ${props.label ? props.label.toLowerCase() : props.name}`} />
            {!!meta.error &&
                <div className="fv-plugins-message-container invalid-feedback"><div>{meta.error}</div></div>}
        </>
    )
})