export interface Box {
    id: string;
    number: string;
    area: number;
    monthlyPrice: number
    isRented: boolean;
    earnedTotal: number;
    totalContracts: number;
    currentOwnerId: string;
    currentOwnerName: string;
    currentOwnerPhone: string;
    currentOwnerEmail: string;
    currentOwnerCurrentBoxes: number;
    currentOwnerTotalContracts: number;
    contractExpiresOn: string;
    currentContractPercentage: number;
    currentContractExpiresInDays: number;
    currentContractFrom: string;
    currentContractTo: string;
    currentContractId: string;
    sizeReadable: string;
    hasReservations: boolean;
    upcomingContract: boolean;
    upcomingReservation: boolean;
    mapX: number;
    mapY: number;
    mapWidth: number;
    mapHeight: number;
}

export class Box implements Box {
    constructor(init?: BoxUpdate) {
        Object.assign(this, init);
    }
}

export class BoxUpdate {
    id?: string = undefined;
    number: string = '';
    area: number = 0;
    monthlyPrice: number = 0

    constructor(box?: Box) {
        if (box) {
            this.id = box.id;
            this.number = box.number;
            this.area = box.area;
            this.monthlyPrice = box.monthlyPrice;
        }
    }
}

export interface Contract {
    id: string;
    customerFullName: string;
    customerPhone: string;
    customerEmail: string;
    customerId: string;
    from: string;
    to: string;
    signedAt: string;
    percentageDone: number;
    boxNumber: string;
    boxArea: string;
    boxMonthlyPrice: string;
    boxId: string;
    isFinished: boolean;
    isFuture: boolean;
    isSigned: boolean;
    canInvoice: boolean;
}

export interface Invoice {
    id: string;
    title: string;
    amount: number;
    issuedOn: string;
    dueDate: string;
    issuedToName: string;
    issuedToAddress1: string;
    issuedToAddress2: string;
    issuedByName: string;
    issuedByAddress1: string;
    issuedByAddress2: string;
    issuedForPeriodFrom: string;
    issuedForPeriodTo: string;
    accountNumber: string;
    isPaid: boolean;
    isSent: boolean;
    markedAsPaidOn: string;
    markedAsPaidByUserName: string;
    isDue: boolean;
    daysDue: number;
    boxId: string;
    customerId: string;
    contractId: string;
    boxNumber: string;
    contractFrom: string;
    contractTo: string;
    contractSignedAt: string;
    typeOfPayment: TypeOfPayment;
}

export enum TypeOfPayment{
    Transfer = "Transfer",
    Card = "Card"
}

export interface Customer {
    id: string;
    fullName: string;
    name: string;
    phone: string;
    email: string;
    rentedBoxes: string;
    totalNumberOfContracts: string;
    createdAt: string;
    invoicesDueNumber: number;
    invoicesDueAmount: number;
    customerRating: number;
    isActive: boolean;
    address1: string;
    address2: string;
    taxNumber: string;
}

export class Customer implements Customer {
    constructor(init?: CustomerAddOrUpdate) {
        Object.assign(this, init);
    }
}

export class CustomerAddOrUpdate {
    id?: string = undefined;
    name: string ='';
    phone: string = '';
    email: string = '';
    address1: string = '';
    address2: string = '';
    taxNumber: string = '';

    constructor(customer?: Customer) {
        if (customer) {
            this.id = customer.id;
            this.name = customer.name;
            this.phone = customer.phone;
            this.email = customer.email;
            this.address1 = customer.address1;
            this.address2 = customer.address2;
            this.taxNumber = customer.taxNumber;
        }
    }
}

export interface BoxRent {
    boxId: string;
    customerId: string;
    from: Date;
    to: Date;
    discountPlans: string[]
}

export interface BoxStatistics {
    currentContractPercentage: number;
    currentContractNote: string;
    currentContractId: string;
    customersTotalNumber: string;
    averageContractDays: number;
    totalRentDays: number;
    rentTimePercentage: string;
    boxStatisticsEvents: BoxStatisticsEvent[];
}

export interface BoxStatisticsEvent {
    time: string;
    timeReadable: string;
    description: string;
    level: string;
    isBold: boolean;
}

export interface Reservation {
    id: string;
    createdAt: string;
    from: string;
    to: string;
    customerId: string;
    customerFullName: string;
    customerPhone: string;
    boxId: string;
    boxNumber: string;
}

export interface ReservationAdd {
    customerId: string;
    boxId: string;
    from: Date;
    to: Date;
}

export interface GeneralForBoxesStatistics {
    boxesRented: number;
    reservations: number;
    activeCustomers: number;
    percentOfAreaRented: number;
    totalArea: number;
}

export interface User {
    username: string;
    token: string;
    role: string;
    email: string;
    initials: string;
}

export interface DiscountPlan {
    id: string;
    name: string;
    createdAt: string;
    lastUpdateAt: string;
    isDeacivated: boolean;
    discounts: number[];
}

export interface DiscountPlanCreateOrUpdate {
    id?: string;
    name: string;
    createdAt: string;
    lastUpdateAt: string;
    discounts: number[];
}


export class DiscountPlanFormValues {
    id?: string = undefined;
    name: string = '';
    m0: number = 0;
    m1: number = 0;
    m2: number = 0;
    m3: number = 0;
    m4: number = 0;
    m5: number = 0;
    m6: number = 0;
    m7: number = 0;
    m8: number = 0;
    m9: number = 0;
    m10: number = 0;
    m11: number = 0;

    constructor(init?: DiscountPlan) {
        if (init) {
            this.id = init.id;
            this.name = init.name;
            this.m0 = init?.discounts[0];
            this.m1 = init?.discounts[1];
            this.m2 = init?.discounts[2];
            this.m3 = init?.discounts[3];
            this.m4 = init?.discounts[4];
            this.m5 = init?.discounts[5];
            this.m6 = init?.discounts[6];
            this.m7 = init?.discounts[7];
            this.m8 = init?.discounts[8];
            this.m9 = init?.discounts[9];
            this.m10 = init?.discounts[10];
            this.m11 = init?.discounts[11];
        }
    }

    public toDiscountPlan(): DiscountPlanCreateOrUpdate {
        return {
            id: this.id,
            name: this.name,
            createdAt: '',
            lastUpdateAt: '',
            discounts: [this.m0, this.m1, this.m2, this.m3, this.m4, this.m5, this.m6, this.m7, this.m8, this.m9, this.m10, this.m11]
        }
    }
}

export interface UserFormValues {
    email: string;
    password: string;
    username?: string;
}

export interface BoxCalendar {
    id: string;
    months: BoxCalendarMonth[];
}

export interface BoxCalendarMonth {
    monthYear: string;
    days: string[];
}

export interface BoxAvailabilityStatus {
    boxId: string;
    isAvailable: boolean;
    comment: string;
}

export interface PasswordUpdate {
    currentPassword: string;
    newPassword: string;
}

export interface PasswordSet {
    uid: string;
    token: string;
    newPassword: string;
}

export interface ResetPassword {
    email: string;
}
