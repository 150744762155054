import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Customer } from '../../../app/models/models';
import { useStore } from '../../../app/stores/store';
import CustomerAddEditModal from '../CustomerAddEdit/CustomerAddEditModal';
import { useTranslation } from 'react-i18next';

interface Props {
    customer: Customer
}
export default observer(function CustomerData({ customer }: Props) {
    const [t] = useTranslation('common');
    const { customerStore: { loadItem } } = useStore();
    const [showNewCustomer, setShowNewCustomer] = useState(false);
    const [editCustomerId, setEditCustomerId] = useState('');

    const handleEditCustomer = (id: string) => {
        setEditCustomerId(id);
    }

    const handleCloseNewCustomer = () => {
        setShowNewCustomer(false);
        setEditCustomerId('');
        loadItem(customer.id);
    }

    return (
        <>
            {/*begin::Card*/}
            <div className="card mb-5 mb-xl-8">
                {/*begin::Card body*/}
                <div className="card-body pt-0 pt-lg-1">
                    {/*begin::Summary*/}
                    {/*begin::Card*/}
                    <div className="">
                        {/*begin::Card body*/}
                        <div className="card-body d-flex flex-center flex-column pt-12 p-9 px-0">
                            {/*begin::Name*/}
                            <span className="fs-1 text-gray-800 fw-bolder mb-3">{t('--Customers.Customer details')}</span>
                            <span className="fs-3 text-gray-800 fw-bolder mb-3">{customer.fullName}</span>
                            {/*end::Name*/}
                            {/*begin::Position*/}
                            <div className="mb-9 mt-5">
                                {/*begin::Badge*/}
                                {customer.isActive ? (
                                    <span className="badge badge-lg badge-light-success d-inline">{t('--Customers.Active')}</span>
                                ) : (
                                    <span className="badge badge-lg badge-light-primary d-inline">{t('--Customers.Currently no boxes')} </span>
                                )}
                                {/*begin::Badge*/}
                            </div>
                            {/*end::Position*/}
                        </div>
                        {/*end::Card body*/}
                    </div>
                    {/*end::Card*/}
                    {/*end::Summary*/}
                    {/*begin::Details toggle*/}
                    <div className="d-flex flex-stack fs-4 py-3">
                        <div className="fw-bolder">{t('--Customers.Basic data')}
                        </div>
                        <span data-bs-toggle="tooltip" data-bs-trigger="hover" title="Edit customer details">
                            <button onClick={() => handleEditCustomer(customer.id)} className="btn btn-sm btn-light-primary">{t('--Common.Edit')}</button>
                        </span>
                    </div>
                    {/*end::Details toggle*/}
                    <div className="separator"></div>
                    {/*begin::Details content*/}
                    <div className="collapse show">
                        <div className="pb-5 fs-6">
                            {/*begin::Details item*/}
                            <div className="fw-bolder mt-5">{t('--Customers.Phone')}</div>
                            <div className="text-gray-600">{customer.phone}</div>
                            {/*begin::Details item*/}
                            {/*begin::Details item*/}
                            <div className="fw-bolder mt-5">{t('--Customers.Email')}</div>
                            <div className="text-gray-600">
                                <a href="\" className="text-gray-600 text-hover-primary">{customer.email}</a>
                            </div>
                            {/*begin::Details item*/}
                            {/*begin::Details item*/}
                            <div className="fw-bolder mt-5">{t('--Customers.Address')}</div>
                            <div className="text-gray-600">{customer.address1}</div>
                            <div className="text-gray-600">{customer.address2}</div>
                            <div className="fw-bolder mt-5">{t('--Customers.Tax number')}</div>
                            <div className="text-gray-600">{customer.taxNumber}</div>
                            {/*begin::Details item*/}
                        </div>
                    </div>
                </div>
                {/*end::Card body*/}
            </div>
            {/*end::Card*/}
            {showNewCustomer &&
                <CustomerAddEditModal
                    OnClose={handleCloseNewCustomer}
                />}
            {editCustomerId &&
                <CustomerAddEditModal
                    OnClose={handleCloseNewCustomer}
                    id={editCustomerId}
                />}
        </>
    )
})

