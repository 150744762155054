import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../app/stores/store';
import { NavLink } from 'react-router-dom';
import urls from '../../app/common/urls';
import { useTranslation } from 'react-i18next';

interface Props {
    id: string;
}

export default observer(function MapBoxDetailsUpcomingEvents({ id }: Props) {
    const [t] = useTranslation('common');

    const { contractStore, reservationStore } = useStore();

    useEffect(() => {
        contractStore.filteringAndPaginationController.clearPredicateAndPaging();
        reservationStore.filteringAndPaginationController.clearPredicateAndPaging();

        contractStore.filteringAndPaginationController.setPredicates([['boxId', id], ['filter', 'future']]);
        reservationStore.filteringAndPaginationController.setPredicate('boxId', id);

    }, [
        id,
        contractStore, contractStore.filteringAndPaginationController.setPredicate, contractStore.loadItems,
        reservationStore, reservationStore.filteringAndPaginationController.setPredicate, reservationStore.loadItems
    ])

    return (
        <>
            {(contractStore.items.length !== 0 || reservationStore.items.length !== 0) &&
                <>
                    <div className="d-flex flex-stack fs-4 py-3">
                        <div className="fw-bolder">{t('--Common.Upcoming events')}</div>
                    </div>
                    <div className="separator mb-5"></div>

                    {contractStore.items.map((i) => (
                        <div key={i.id} className="d-flex align-items-sm-center mb-7">
                            <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                <div className="flex-grow-1 me-2">
                                    <NavLink to={urls.customerDetails(i.customerId)} className="text-gray-800 text-hover-primary fs-6 fw-bolder">{i.customerFullName}</NavLink>
                                    <span className="text-muted fw-bold d-block fs-7">{i.from} - {i.to}</span>
                                </div>
                                <NavLink to={urls.contractDetails(i.id)}><span className="badge badge-lg badge-light-info fw-bolder my-2">{t('--Common.Contract')}</span></NavLink>
                            </div>
                        </div>
                    ))}

                    {reservationStore.items.map((i) => (
                        <div key={i.id} className="d-flex align-items-sm-center mb-7">
                            <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                <div className="flex-grow-1 me-2">
                                    <NavLink to={urls.customerDetails(i.customerId)} className="text-gray-800 text-hover-primary fs-6 fw-bolder">{i.customerFullName}</NavLink>
                                    <span className="text-muted fw-bold d-block fs-7">{i.from} - {i.to}</span>
                                </div>
                                <span className="badge badge-lg badge-light-primary fw-bolder my-2">{t('--Common.Reservation')}</span>
                            </div>
                        </div>
                    ))}
                </>}
        </>
    )
})
