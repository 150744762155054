import React, { useRef } from "react";
import PropTypes from "prop-types";
import { useStore } from "../stores/store";

function AdminRequiredWrapper(props: any) {
    const wrapperRef = useRef(null);
    const { userStore } = useStore();
    if (!userStore.isAdmin()) {
        return <></>
    }
    return <div ref={wrapperRef}>{props.children}</div>;
}

AdminRequiredWrapper.propTypes = {
    children: PropTypes.element.isRequired,
};

export default AdminRequiredWrapper;
