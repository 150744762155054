import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../app/stores/store';
import BoxContractCard from '../../boxes/BoxDetails/BoxContractCard';

export default observer(function CustomerContracts() {
	const [t] = useTranslation('common');
	const { contractStore } = useStore();
	const { setPredicate } = contractStore.filteringAndPaginationController;

	const handleFilterChange = (event: any) => {
		setPredicate('filter', event.target.value);
	}

	const handleSearchChange = (event: any) => {
		const text = event.target.value;
		if (text === '') {
			setPredicate('search', event.target.value)
		}
		else if (text.length >= 3) {
			setPredicate('search', event.target.value)
		}
	}

	return (
		<>
			{/*begin::Toolbar*/}
			<div className="d-flex flex-wrap flex-stack pb-7">
				{/*begin::Title*/}
				<div className="d-flex flex-wrap align-items-center my-1">
					{/*begin::Search*/}
					<div className="d-flex align-items-center position-relative my-1">
						{/*begin::Svg Icon | path: icons/duotone/General/Search.svg*/}
						<span className="svg-icon svg-icon-3 position-absolute ms-3">
							<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
								<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
									<rect x="0" y="0" width="24" height="24" />
									<path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
									<path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fillRule="nonzero" />
								</g>
							</svg>
						</span>
						{/*end::Svg Icon*/}
						<input onChange={handleSearchChange} type="text" className="form-control form-control-white form-control-sm w-275px ps-9" placeholder={t('--Common.Search (3 letters mi')} />
					</div>
					<select name="status" onChange={handleFilterChange} className="form-select form-select-white form-select-sm w-275px me-0" style={{ marginLeft: "20px" }}>
					<option value="all">{t('--Common.All')}</option>
						<option value="current">{t('--Common.In Progress')}</option>
						<option value="future">{t('--Common.Future')}</option>
					</select>
					{/*end::Search*/}
				</div>
				{/*end::Title*/}
				{/*begin::Controls*/}
				<div className="d-flex flex-wrap my-1">
					{/*begin::Actions*/}
					<div className="d-flex my-0">
						{/*begin::Select*/}

						{/*end::Select*/}
					</div>
					{/*end::Actions*/}
				</div>
				{/*end::Controls*/}
			</div>
			{/*end::Toolbar*/}
			<div className="row g-6 g-xl-9">
				{contractStore.items.map((c) => (
					<div key={c.id} className="col-md-6 col-xl-6">
						<BoxContractCard key={c.id} contract={c} />
					</div>
				))}
			</div>
		</>
	)
})
