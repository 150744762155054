import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import ConfirmationModal from '../../../app/common/ConfirmationModal';
import urls from '../../../app/common/urls';
import { TypeOfPayment } from '../../../app/models/models';
import { useStore } from '../../../app/stores/store';

export default observer(function InvoicePage() {
    const [t] = useTranslation('common');
    const { invoiceStore } = useStore();
    const { id } = useParams<{ id: string }>();
    const { selectedItem } = invoiceStore;
    const [sentToEmail, setSentToEmail] = useState(false);
    useEffect(() => {
        invoiceStore.loadItem(id);
        invoiceStore.filteringAndPaginationController.clearPredicateAndPaging();
    }, [
        id,
        invoiceStore, invoiceStore.filteringAndPaginationController.setPredicate])

    const [showConfirmation, setShowConfirmation] = useState(false)
    const handleYes = () => {
        setShowConfirmation(false);
        invoiceStore.markAsPaid(id);
        window.location.reload();
    }

    const sendOnEmail = () => {
        invoiceStore.sendOnEmail(id);
        setSentToEmail(true);
    }

    return (
        <>
            {selectedItem &&
                <div id="kt_content_container" className="container">
                    <div className="card">
                        {/*begin::Body*/}
                        <div className="card-body p-lg-20">
                            {/*begin::Layout*/}
                            <div className="d-flex flex-column flex-xl-row">
                                {/*begin::Content*/}
                                <iframe title='invoiceprint' style={{ minHeight: "700px" }} className="flex-lg-row-fluid me-xl-18 mb-10 mb-xl-0" src={`${process.env.REACT_APP_API_URL}/DocumentPrint/invoice/${id}`} />
                                {/*end::Content*/}
                                {/*begin::Sidebar*/}
                                <div className="m-0">
                                    {/*begin::Invoice 2 sidebar*/}
                                    <div className="border border-dashed border-gray-300 card-rounded h-lg-100 min-w-md-350px p-9 bg-lighten">
                                        {!selectedItem.isPaid &&
                                            <button className="btn btn-success btn-shadow-hover font-weight-bolder w-100 py-3 mb-5" onClick={() => setShowConfirmation(true)}>{t("--Invoices.Mark as paid")}</button>}
                                        {/*begin::Title*/}
                                        <h6 className="mb-4 fw-boldest text-gray-600" style={{ textTransform: "uppercase" }}>{t('--Invoices.PAYMENT DETAILS')}</h6>
                                        {/*end::Title*/}
                                        {/*begin::Item*/}
                                        <div className="mb-4">
                                            <div className="fw-bold text-gray-600 fs-7">{t('--Invoices.Status')}</div>
                                            {/*begin::Labels*/}
                                            <div className="mt-2">
                                                {selectedItem.isPaid &&
                                                    <span className="badge badge-lg badge-success me-2">{t("--Common.Paid")}</span>}
                                                {!selectedItem.isPaid &&
                                                    <span className="badge badge-lg badge-light-danger me-2">{t("--Common.Not paid")}</span>}
                                                {selectedItem.isDue &&
                                                    <span className="badge badge-lg badge-danger">{t("--Common.Due")}</span>}
                                                {!selectedItem.isSent &&
                                                    <>
                                                        <br />
                                                        <span className="badge badge-lg badge-danger mt-4">{t("--Common.Probably not sent")}</span>
                                                        <div className="fw-bolder text-gray-800 fs-6 mt-2">{t("--Common.Please contact the Administrator")}</div>
                                                    </>}
                                            </div>
                                            {/*end::Labels*/}
                                        </div>
                                        {/*end::Item*/}
                                        {/*begin::Item*/}
                                        <div className="mb-4">
                                            <div className="fw-bold text-gray-600 fs-7">{t('--Invoices.Marked as paid on')}</div>
                                            <div className="fw-bolder text-gray-800 fs-6">{selectedItem.markedAsPaidOn}</div>
                                        </div>
                                        {/*end::Item*/}
                                        {/*begin::Item*/}
                                        <div className="mb-4">
                                            <div className="fw-bold text-gray-600 fs-7">{t('--Invoices.Paid by')}</div>
                                            {selectedItem.typeOfPayment === TypeOfPayment.Transfer &&
                                                < div className="fw-bolder text-gray-800 fs-6">{t('--Invoices.Transfer')}</div>}
                                            {selectedItem.typeOfPayment === TypeOfPayment.Card &&
                                                < div className="fw-bolder text-gray-800 fs-6">{t('--Invoices.Card')}</div>}
                                        </div>
                                        {/*end::Item*/}
                                        {/*begin::Item*/}
                                        <div className="mb-4">
                                            <div className="fw-bold text-gray-600 fs-7">{t('--Invoices.Marked as paid by')}</div>
                                            <div className="fw-bolder text-gray-800 fs-6">{selectedItem.markedAsPaidByUserName}
                                            </div>
                                        </div>
                                        {/*end::Item*/}
                                        {/*begin::Item*/}
                                        <div className="mb-8">
                                            <div className="fw-bold text-gray-600 fs-7">{t('--Invoices.Due date')}</div>
                                            <div className="fw-bolder fs-6 text-gray-800 d-flex align-items-center">{selectedItem.dueDate}
                                                {selectedItem.daysDue !== 0 &&
                                                    <span className="fs-7 text-danger d-flex align-items-center">
                                                        <span className="bullet bullet-dot bg-danger mx-2"></span>
                                                        Due in {selectedItem.daysDue} days
                                                    </span>}
                                            </div>
                                        </div>
                                        {/*end::Item*/}
                                        {/*begin::Title*/}
                                        <h6 className="mb-4 fw-boldest text-gray-600" style={{ textTransform: "uppercase" }}>{t('--Invoices.CONTRACT DETAILS')}</h6>
                                        <div className="mb-4">
                                            <div className="fw-bold text-gray-600 fs-7">{t('--Invoices.Contract for period')}</div>
                                            <div className="fw-bolder text-gray-800 fs-6">{selectedItem.contractFrom} - {selectedItem.contractTo}</div>
                                        </div>
                                        <div className="mb-4">
                                            <div className="fw-bold text-gray-600 fs-7">{t('--Invoices.Contract signed at')}</div>
                                            <div className="fw-bolder text-gray-800 fs-6">{selectedItem.contractSignedAt}</div>
                                        </div>
                                        <div className="mb-4">
                                            <div className="fw-bold text-gray-600 fs-7">{t('--Invoices.Contract for box')}</div>
                                            <div className="fw-bolder text-gray-800 fs-6">#{selectedItem.boxNumber}</div>
                                        </div>
                                        <NavLink to={urls.contractDetails(selectedItem.contractId)} className="btn btn-light-success btn-shadow-hover font-weight-bolder w-100 py-3 mb-5 px-0">{t('--Invoices.Go to contract')}</NavLink>
                                        <NavLink to={urls.boxDetails(selectedItem.boxId)} className="btn btn-light-success btn-shadow-hover font-weight-bolder w-100 py-3 mb-5 px-0">{t('--Invoices.Go to box')}</NavLink>
                                        {sentToEmail &&
                                            <button onClick={() => sendOnEmail()} className="btn btn-success btn-shadow-hover font-weight-bolder w-100 py-3 px-0" disabled>{t('--Common.Done')}</button>}
                                        {!sentToEmail &&
                                            <button onClick={() => sendOnEmail()} className="btn btn-warning btn-shadow-hover font-weight-bolder w-100 py-3 px-0">{t('--Common.Send')}</button>}
                                        {/* <button className="btn btn-warning btn-shadow-hover font-weight-bolder py-3 mt-5" onClick={() => sendOnEmail()}>{t("--Contract.Invoice one period")}</button> */}

                                        {/*end::Title*/}
                                    </div>
                                    {/*end::Invoice 2 sidebar*/}
                                </div>
                                {/*end::Sidebar*/}
                            </div>
                            {/*end::Layout*/}
                        </div>
                        {/*end::Body*/}
                    </div>
                </div>
            }
            {showConfirmation &&
                <ConfirmationModal
                    OnClose={() => setShowConfirmation(false)}
                    OnYes={handleYes}
                    label={t('--Invoices.Are you sure you want to mark this invoice as paid?')}
                />}
        </>
    )
})
