import React from 'react';
import { observer } from 'mobx-react-lite';

interface Props {
    message: string
    success: boolean,
    errors?: string[]
}


export default observer(function ModalConfirmation({ message, success, errors }: Props) {
    return (
        <>
            <div className="swal2-center">
                <div aria-labelledby="swal2-title" className="swal2-modal swal2-icon-error swal2-show" tabIndex={-1} style={{ display: "block" }}>
                    <div className="swal2-header">
                        {!success &&
                            <div className="swal2-icon swal2-error swal2-icon-show" style={{ display: "flex" }}>
                                <span className="swal2-x-mark">
                                    <span className="swal2-x-mark-line-left"></span>
                                    <span className="swal2-x-mark-line-right"></span>
                                </span>
                            </div>}
                        {success &&
                            <div className="swal2-icon swal2-success swal2-icon-show" style={{ display: "flex" }}><div className="swal2-success-circular-line-left" style={{ backgroundColor: "rgb(255, 255, 255)" }}></div>
                                <span className="swal2-success-line-tip"></span> <span className="swal2-success-line-long"></span>
                                <div className="swal2-success-ring"></div> <div className="swal2-success-fix" style={{ backgroundColor: "rgb(255, 255, 255)" }}></div>
                                <div className="swal2-success-circular-line-right" style={{ backgroundColor: "rgb(255, 255, 255)" }}></div>
                            </div>}
                    </div>
                    <div className="swal2-content">
                        <div id="swal2-content" className="swal2-html-container mb-5" style={{ display: "block" }}>{message}</div>
                        {errors?.map(err => (
                            <div id="swal2-content" className="swal2-html-container text-danger" style={{ display: "block" }}>{err}</div>
                        ))}
                    </div>
                </div>
            </div></>
    )
})
