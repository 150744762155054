import React from 'react';
import { observer } from 'mobx-react-lite';
import { Contract } from '../../../app/models/models';
import { NavLink } from 'react-router-dom';
import urls from '../../../app/common/urls';
import { useTranslation } from 'react-i18next';

interface Props {
	contract: Contract
}

export default observer(function BoxContractCArd({ contract }: Props) {
    const [t] = useTranslation('common');
	return (
		<>
			<NavLink to={urls.contractDetails(contract.id)} className="card border border-2 border-gray-300 border-hover">
				{/*begin::Card header*/}
				<div className="card-header border-0 pt-9">
					{/*begin::Card Title*/}
					<div className="card-title m-0"> {t('--Common.Contract')}
					</div>
					{/*end::Car Title*/}
					{/*begin::Card toolbar*/}
					<div className="card-toolbar">
						{contract.isFinished &&
						<span className="badge badge-light-success fw-bolder me-auto px-4 py-3">{t('--Common.Finished')}</span>}
						{contract.isFuture &&
						<span className="badge badge-light-info fw-bolder me-auto px-4 py-3">{t('--Common.Future')}</span>}
						{!contract.isFuture && !contract.isFinished &&
						<span className="badge badge-light-primary fw-bolder me-auto px-4 py-3">{t('--Common.In Progress')}</span>}
					</div>
					{/*end::Card toolbar*/}
				</div>
				{/*end:: Card header*/}
				{/*begin:: Card body*/}
				<div className="card-body p-9">
					{/*begin::Name*/}
					<div className="fs-3 fw-bolder text-dark">{contract.customerFullName} - Box #{contract.boxNumber}</div>
					{/*end::Name*/}
					{/*begin::Description*/}
					<p className="text-gray-400 fw-bold fs-5 mt-1 mb-7">{t('--Contracts.Signed at')} {contract.signedAt}</p>
					{/*end::Description*/}
					{/*begin::Info*/}
					<div className="d-flex flex-wrap mb-5">
						{/*begin::Due*/}
						<div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3">
							<div className="fs-6 text-gray-800 fw-bolder">{contract.from}</div>
							<div className="fw-bold text-gray-400">{t('--Common.From')}</div>
						</div>
						{/*end::Due*/}
						{/*begin::Budget*/}
						<div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3">
							<div className="fs-6 text-gray-800 fw-bolder">{contract.to}</div>
							<div className="fw-bold text-gray-400">{t('--Common.To')}</div>
						</div>
						{/*end::Budget*/}
					</div>
					{/*end::Info*/}
					{/*begin::Progress*/}
					<div className="h-4px w-100 bg-light mb-5" data-bs-toggle="tooltip" title="This project 100% completed">
						<div className={`bg-${contract.isFinished?'success':'primary'} rounded h-4px`} role="progressbar" style={{ width: `${contract.percentageDone}%` }} aria-valuenow={contract.percentageDone} aria-valuemin={0} aria-valuemax={100}></div>
					</div>
					{/*end::Progress*/}
				</div>
				{/*end:: Card body*/}
			</NavLink>
		</>
	)
})