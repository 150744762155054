import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { GeneralForBoxesStatistics } from "../models/models";

export default class StatisticsStore{
    generalForBoxesStatistics: GeneralForBoxesStatistics | undefined = undefined;

    constructor(){
        makeAutoObservable(this);
    }

    loadStatistics = async () =>{
        let stats = await agent.Statistics.generalForBoxes();
        runInAction(() => {
            this.generalForBoxesStatistics = stats;
        })
    }
}