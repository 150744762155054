import { observer } from 'mobx-react-lite';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useStore } from '../../../app/stores/store';
import CustomerContracts from './CustomerContracts';
import CustomerData from './CustomerData';
import CustomerInvoices from './CustomerInvoices';
import CustomerReservations from './CustomerReservations';
export default observer(function BoxPage() {
    const [t] = useTranslation('common');
    const { boxStore, contractStore, invoiceStore, reservationStore, customerStore } = useStore();
    const { id } = useParams<{ id: string }>();
    const { selectedItem } = customerStore;
    useEffect(() => {
        customerStore.loadItem(id);
        contractStore.filteringAndPaginationController.clearPredicateAndPaging();
        boxStore.filteringAndPaginationController.clearPredicateAndPaging();
        invoiceStore.filteringAndPaginationController.clearPredicateAndPaging();
        reservationStore.filteringAndPaginationController.clearPredicateAndPaging();
        contractStore.filteringAndPaginationController.setPredicate('customerId', id);
        boxStore.filteringAndPaginationController.setPredicate('customerId', id);
        invoiceStore.filteringAndPaginationController.setPredicate('customerId', id);
        reservationStore.filteringAndPaginationController.setPredicate('customerId', id);
    }, [
        id,
        customerStore,
        boxStore.loadItem, boxStore,
        contractStore, contractStore.filteringAndPaginationController.setPredicate,
        invoiceStore, invoiceStore.filteringAndPaginationController.setPredicate,
        reservationStore, reservationStore.filteringAndPaginationController.setPredicate])

    const [tab, setTab] = useState('contracts');

    const renderButton = (key: string, text: string) => {
        return (
            <>
                <button
                    className={`nav-link text-active-primary pb-4 fw-bolder ${tab === key ? 'active' : ''}`}
                    onClick={() => setTab(key)}>
                    {text}
                </button>
            </>
        )
    }

    return (
        <>
            {selectedItem &&
                <div id="kt_content_container" className="container">
                    <div className="row">
                        <div className="col-4">
                            <CustomerData customer={selectedItem} />
                            {/* <CustomerStatistics2 customer={selectedItem} /> */}
                            {/* <CustomerStatistics customer={selectedItem} /> */}
                        </div>
                        <div className="col-8">
                            <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bolder mb-8">
                                {/*begin:::Tab item*/}
                                {//contractStore.items.length !== 0 &&
                                    <li className="nav-item">
                                        {renderButton('contracts', t('--Navigation.Contracts'))}
                                    </li>}
                                {/*end:::Tab item*/}
                                {/*begin:::Tab item*/}
                                {//reservationStore.items.length !== 0 &&
                                    <li className="nav-item">
                                        {renderButton('reservations', t('--Navigation.Reservations'))}
                                    </li>}
                                {/*end:::Tab item*/}
                                {/*begin:::Tab item*/}
                                {//invoiceStore.items.length !== 0 &&
                                    <li className="nav-item">
                                        {renderButton('invoices', t('--Navigation.Invoices'))}
                                    </li>}
                            </ul>
                            {/* <CustomerInvoices /> */}
                            {/* <CustomerContracts /> */}
                            {tab === 'reservations' &&
                                <CustomerReservations />}
                            {tab === 'invoices' &&
                                <CustomerInvoices />}
                            {tab === 'contracts' &&
                                <CustomerContracts />}
                        </div>
                    </div>
                </div>}
        </>
    )
})
