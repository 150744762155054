import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import OutsideClickDetector from '../common/OutsideClickDetector';
import { useStore } from '../stores/store';
import ChangePasswordModal from '../../features/users/ChangePasswordModal';
import { useTranslation } from 'react-i18next';

export default observer(function Header() {
    const { userStore } = useStore();
    const [showMenu, setShowMenu] = useState(false);
    const [showLanguages, setShowLanguages] = useState(false);
    const [menuX, setMenuX] = useState(0);
    
    const toggleMenu = () => {
        let elem = document.querySelector('#kt_header_user_menu_toggle');
        let rect = elem?.getBoundingClientRect();
        if (!rect) return;
        setMenuX(rect['x'])
        setShowMenu(!showMenu);
    }
    
    const toggleLanguages = () => {
        let elem = document.querySelector('#kt_header_user_menu_toggle');
        let rect = elem?.getBoundingClientRect();
        if (!rect) return;
        setMenuX(rect['x'])
        setShowLanguages(!showLanguages);
    }
    
    const clickElswhere = () => {
        setShowLanguages(false);
        setShowMenu(false);
    }
    
    const handleSignOut = () => {
        userStore.logout();
    }

    const [showChangePassword, setShowChangePassword] = useState(false);
    
    const [t, i18n] = useTranslation('common');
    
    const languages: {[code: string] : {name: string, flag: string}} = {
        "en-US": {name: "English", flag:"united-states.svg"},
        "pl-PL": {name: "Polski", flag:"poland.svg"},
    }

    const changeLanguage = (language: string) => {
        i18n.changeLanguage(language);
        clickElswhere();
    }
    return (
        <>
            {/*begin::Header*/}
            <div id="kt_header" className="header align-items-stretch">
                {/*begin::Container*/}
                <div className="container-fluid d-flex align-items-stretch justify-content-between">
                    {/*begin::Aside mobile toggle*/}
                    <div className="d-flex align-items-center d-lg-none ms-n3 me-1" title="Show aside menu">
                        <div className="btn btn-icon btn-active-light-primary" id="kt_aside_mobile_toggle">
                            {/*begin::Svg Icon | path: icons/duotone/Text/Menu.svg*/}
                            <span className="svg-icon svg-icon-2x mt-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <rect x="0" y="0" width="24" height="24" />
                                        <rect fill="#000000" x="4" y="5" width="16" height="3" rx="1.5" />
                                        <path d="M5.5,15 L18.5,15 C19.3284271,15 20,15.6715729 20,16.5 C20,17.3284271 19.3284271,18 18.5,18 L5.5,18 C4.67157288,18 4,17.3284271 4,16.5 C4,15.6715729 4.67157288,15 5.5,15 Z M5.5,10 L18.5,10 C19.3284271,10 20,10.6715729 20,11.5 C20,12.3284271 19.3284271,13 18.5,13 L5.5,13 C4.67157288,13 4,12.3284271 4,11.5 C4,10.6715729 4.67157288,10 5.5,10 Z" fill="#000000" opacity="0.3" />
                                    </g>
                                </svg>
                            </span>
                            {/*end::Svg Icon*/}
                        </div>
                    </div>
                    {/*end::Aside mobile toggle*/}
                    {/*begin::Mobile logo*/}
                    <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                        <a href="index.html" className="d-lg-none">
                            <img alt="Logo" src="assets/media/logos/logo-3.svg" className="h-30px" />
                        </a>
                    </div>
                    {/*end::Mobile logo*/}
                    {/*begin::Wrapper*/}
                    <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
                        {/*begin::Navbar*/}
                        <div className="d-flex align-items-stretch" id="kt_header_nav">
                        </div>
                        {/*end::Navbar*/}
                        {/*begin::Topbar*/}
                        <div className="d-flex align-items-stretch flex-shrink-0">
                            {/*begin::Toolbar wrapper*/}
                            <div className="d-flex align-items-stretch flex-shrink-0">
                                {/* <div className="d-flex align-items-center ms-1 ms-lg-3">
                                    <div className="btn btn-icon btn-active-light-primary position-relative w-30px h-30px w-md-40px h-md-40px" id="kt_activities_toggle">
                                        <span className="svg-icon svg-icon-muted svg-icon-1"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path opacity="0.25" fillRule="evenodd" clipRule="evenodd" d="M3.11117 13.2288C3.27137 11.0124 5.01376 9.29156 7.2315 9.15059C8.55778 9.06629 10.1795 9 12 9C13.8205 9 15.4422 9.06629 16.7685 9.15059C18.9862 9.29156 20.7286 11.0124 20.8888 13.2288C20.9535 14.1234 21 15.085 21 16C21 16.915 20.9535 17.8766 20.8888 18.7712C20.7286 20.9876 18.9862 22.7084 16.7685 22.8494C15.4422 22.9337 13.8205 23 12 23C10.1795 23 8.55778 22.9337 7.23151 22.8494C5.01376 22.7084 3.27137 20.9876 3.11118 18.7712C3.04652 17.8766 3 16.915 3 16C3 15.085 3.04652 14.1234 3.11117 13.2288Z" fill="#12131A" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M13 16.7324C13.5978 16.3866 14 15.7403 14 15C14 13.8954 13.1046 13 12 13C10.8954 13 10 13.8954 10 15C10 15.7403 10.4022 16.3866 11 16.7324V18C11 18.5523 11.4477 19 12 19C12.5523 19 13 18.5523 13 18V16.7324Z" fill="#12131A" />
                                            <path fillRule="evenodd" clipRule="evenodd" d="M7 6C7 3.23858 9.23858 1 12 1C14.7614 1 17 3.23858 17 6V10C17 10.5523 16.5523 11 16 11C15.4477 11 15 10.5523 15 10V6C15 4.34315 13.6569 3 12 3C10.3431 3 9 4.34315 9 6V10C9 10.5523 8.55228 11 8 11C7.44772 11 7 10.5523 7 10V6Z" fill="#12131A" />
                                        </svg></span>
                                        <span className="bullet bullet-dot bg-danger h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink"></span>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center ms-1 ms-lg-3">
                                    <div className="btn btn-icon btn-active-light-primary position-relative w-30px h-30px w-md-40px h-md-40px" id="kt_drawer_chat_toggle">
                                        <span className="svg-icon svg-icon-muted svg-icon-1"><svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <polygon points="0 0 24 0 24 24 0 24" />
                                                <path d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                                <rect fill="#000000" x="6" y="11" width="9" height="2" rx="1" />
                                                <rect fill="#000000" x="6" y="15" width="5" height="2" rx="1" />
                                            </g>
                                        </svg></span>
                                        <span className="bullet bullet-dot bg-danger h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink"></span>
                                    </div>
                                </div> */}
                                {/*begin::User*/}
                                <div className="d-flex align-items-center ms-1 ms-lg-3">
                                    {/*begin::Menu wrapper*/}
                                    <div className="cursor-pointer symbol symbol-30px symbol-md-40px badge-light-success" id="kt_header_user_menu_toggle" onClick={() => toggleMenu()} >
                                        <span className="symbol-label font-size-h5 font-weight-bold">{userStore.user?.initials}</span>
                                    </div>
                                    {/*begin::Menu*/}
                                    {showMenu &&
                                        <>
                                            <OutsideClickDetector onClickOutside={() => clickElswhere()}>
                                                <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold py-4 fs-6 w-350px show"
                                                    style={{
                                                        zIndex: 105,
                                                        position: "fixed",
                                                        inset: "0px auto auto 0px",
                                                        margin: "0px",
                                                        transform: `translate(${menuX - 320}px, 65px)`,
                                                    }}
                                                >
                                                    {/*begin::Menu item*/}
                                                    <div className="menu-item px-3">
                                                        <div className="menu-content d-flex align-items-center px-3">
                                                            {/*begin::Avatar*/}
                                                            <div className="symbol symbol-50px me-5 symbol-light-success">
                                                                <span className="symbol-label font-size-h5 font-weight-bold">{userStore.user?.initials}</span>
                                                            </div>
                                                            {/*end::Avatar*/}
                                                            {/*begin::Username*/}
                                                            <div className="d-flex flex-column">
                                                                <div className="fw-bolder d-flex align-items-center fs-5">{userStore.user?.username}
                                                                    <span className="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">{userStore.user?.role}</span></div>
                                                                <p className="fw-bold text-muted fs-7">{userStore.user?.email}</p>
                                                            </div>
                                                            {/*end::Username*/}
                                                        </div>
                                                    </div>
                                                    {/*end::Menu item*/}

                                                    {/*begin::Menu separator*/}
                                                    <div className="separator my-2"></div>
                                                    {/*end::Menu separator*/}
                                                    {/*begin::Menu item*/}
                                                    <div className="menu-item px-5">
                                                        <div className="menu-link px-5 show menu-dropdown" onClick={() => toggleLanguages()}>
                                                            <span className="menu-title position-relative">{t('--Navigation.Language')}
                                                                <span className="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">{languages[i18n.language].name}
                                                                    <img className="w-15px h-15px rounded-1 ms-2" src={`assets/media/flags/${languages[i18n.language].flag}`} alt="metronic" /></span></span>
                                                        </div>
                                                        {/*begin::Menu sub*/}
                                                        {showLanguages &&
                                                            <div className="menu-sub menu-sub-dropdown w-175px py-4 show"
                                                                style={{
                                                                    display: 'flex',
                                                                    zIndex: 106,
                                                                    position: "fixed",
                                                                    inset: "0px 0px auto auto",
                                                                    margin: "0px",
                                                                    transform: `translate(-225px, 100px)`,
                                                                }}
                                                            >
                                                                {/*begin::Menu item*/}
                                                                <div className="menu-item px-3">
                                                                    <span onClick={() => changeLanguage('en-US')} className="menu-link d-flex px-5">
                                                                        <span className="symbol symbol-20px me-4">
                                                                            <img className="rounded-1" src="assets/media/flags/united-states.svg" alt="metronic" />
                                                                        </span>English</span>
                                                                </div>
                                                                {/*end::Menu item*/}
                                                                {/*begin::Menu item*/}
                                                                {/* <div className="menu-item px-3">
                                                                    <span onClick={() => changeLanguage('es')} className="menu-link d-flex px-5">
                                                                        <span className="symbol symbol-20px me-4">
                                                                            <img className="rounded-1" src="assets/media/flags/spain.svg" alt="metronic" />
                                                                        </span>Spanish</span>
                                                                </div> */}
                                                                {/*end::Menu item*/}
                                                                {/*begin::Menu item*/}
                                                                {/* <div className="menu-item px-3">
                                                                    <span onClick={() => changeLanguage('de')} className="menu-link d-flex px-5">
                                                                        <span className="symbol symbol-20px me-4">
                                                                            <img className="rounded-1" src="assets/media/flags/germany.svg" alt="metronic" />
                                                                        </span>German</span>
                                                                </div> */}
                                                                {/*end::Menu item*/}
                                                                {/*begin::Menu item*/}
                                                                {/* <div className="menu-item px-3">
                                                                    <span onClick={() => changeLanguage('jp')} className="menu-link d-flex px-5">
                                                                        <span className="symbol symbol-20px me-4">
                                                                            <img className="rounded-1" src="assets/media/flags/japan.svg" alt="metronic" />
                                                                        </span>Japanese</span>
                                                                </div> */}
                                                                {/*end::Menu item*/}
                                                                {/*begin::Menu item*/}
                                                                {/* <div className="menu-item px-3">
                                                                    <span onClick={() => changeLanguage('fr')} className="menu-link d-flex px-5">
                                                                        <span className="symbol symbol-20px me-4">
                                                                            <img className="rounded-1" src="assets/media/flags/france.svg" alt="metronic" />
                                                                        </span>French</span>
                                                                </div> */}
                                                                {/*end::Menu item*/}
                                                                 {/*begin::Menu item*/}
                                                                 <div className="menu-item px-3">
                                                                    <span onClick={() => changeLanguage('pl-PL')} className="menu-link d-flex px-5">
                                                                        <span className="symbol symbol-20px me-4">
                                                                            <img className="rounded-1" src="assets/media/flags/poland.svg" alt="metronic" />
                                                                        </span>Polski</span>
                                                                </div>
                                                                {/*end::Menu item*/}
                                                            </div>}
                                                        {/*end::Menu sub*/}
                                                    </div>
                                                    {/*end::Menu item*/}
                                                    {/*begin::Menu separator*/}
                                                    <div className="separator my-2"></div>
                                                    {/*end::Menu separator*/}
                                                    {/*begin::Menu item*/}
                                                    <div className="menu-item px-5 my-1">
                                                        <span onClick={() => { setShowChangePassword(true); clickElswhere() }} className="menu-link px-5">{t('--Navigation.Change Password')}</span>
                                                    </div>
                                                    {/*end::Menu item*/}
                                                    {/*begin::Menu item*/}
                                                    <div className="menu-item px-5">
                                                        <span onClick={() => handleSignOut()} className="menu-link px-5">{t('--Navigation.Sign Out')}</span>
                                                    </div>
                                                    {/*end::Menu item*/}
                                                </div>
                                                {/*end::Menu*/}
                                            </OutsideClickDetector>
                                        </>}
                                    {/*end::Menu wrapper*/}
                                </div>
                                {/*end::User */}
                                {/*begin::Heaeder menu toggle*/}
                            </div>
                            {/*end::Toolbar wrapper*/}
                        </div>
                        {/*end::Topbar*/}
                    </div>
                    {/*end::Wrapper*/}
                </div>
                {/*end::Container*/}
            </div>
            {/*end::Header*/}
            {showChangePassword &&
                <ChangePasswordModal OnClose={() => setShowChangePassword(false)} />}

        </>
    )
})
