import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Formik, Form } from 'formik';
import { BoxUpdate } from '../../../app/models/models';
import * as Yup from 'yup'
import TextInput from '../../../app/elements/TextInput';
import { useStore } from '../../../app/stores/store';
import ModalConfirmation from '../../../app/elements/ModalConfirmation';
import { runInAction } from 'mobx';
import Result from '../../../app/api/result';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
    OnClose(): void
    OnGoToItem?(itemId: string): void
    id?: string
}

export default observer(function BoxEditModal({ OnClose, OnGoToItem, id }: Props) {
    const [t] = useTranslation('common');
    const { boxStore } = useStore();
    const { loadItem } = boxStore;
    const [itemLabel, setItemLabel] = useState('');
    const [result, setResult] = useState<Result | null>(null);
    const [item, setItem] = useState<BoxUpdate>(new BoxUpdate());
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (id) loadItem(id).then((item) => {
            setItem(new BoxUpdate(item))
            setLoading(false);
        })
        else setLoading(false);
    }, [id, loadItem, setItem])


    const validationSchema = Yup.object({
        area: Yup.number().positive('Area must be a positive number').required('Area must be a positive number'),
        number: Yup.string().required('Number is required'),
        monthlyPrice: Yup.number().positive('Monthly price must be a positive number').required('Monthly price must be a positive number'),
    })

    async function handleSubmit(item: BoxUpdate) {
        setItemLabel(`${item.number}`);
        var resultOfCall = await boxStore.update(item);
        runInAction(() => {
            setItem(item);
            setResult(resultOfCall);
        })
    }

    return (
        <>
            <div className="modal fade show" tabIndex={-1} style={{ display: 'block' }}>
                {/*begin::Modal dialog*/}
                <div className="modal-dialog modal-dialog-centered mw-650px">
                    {/*begin::Modal content*/}
                    <div className="modal-content rounded">
                        {/*begin::Modal header*/}
                        <div className="modal-header pb-0 border-0 justify-content-end">
                            {/*begin::Close*/}
                            <div onClick={() => OnClose()} className="btn btn-sm btn-icon btn-active-color-primary">
                                {/*begin::Svg Icon | path: icons/duotone/Navigation/Close.svg*/}
                                <span className="svg-icon svg-icon-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                            <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1"></rect>
                                            <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1"></rect>
                                        </g>
                                    </svg>
                                </span>
                                {/*end::Svg Icon*/}
                            </div>
                            {/*end::Close*/}
                        </div>
                        {/*begin::Modal header*/}
                        {/*begin::Modal body*/}
                        <div className="modal-body scroll-y px-10 px-lg-15 pt-0 pb-15" data-select2-id="select2-data-72-z5wg">
                            {/*begin:Form*/}
                            {!loading && !result && item &&
                                <Formik
                                    enableReinitialize
                                    initialValues={item!}
                                    onSubmit={values => handleSubmit(values)}
                                    validationSchema={validationSchema}
                                    validateOnMount
                                >
                                    {({ handleSubmit, isValid, isSubmitting, dirty }) => (
                                        <Form className="form fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={handleSubmit} autoComplete="off" >
                                            {/*begin::Heading*/}
                                            <div className="mb-13 text-center">
                                                {/*begin::Title*/}
                                                <h1 className="mb-3">{t('--BoxDetails.Edit box')}</h1>
                                                {/*end::Title*/}
                                                {/*begin::Description*/}
                                                <div className="text-gray-400 fw-bold fs-5">{t('--EditBox.Please provide all r')}</div>
                                                {/*end::Description*/}
                                            </div>
                                            {/*end::Heading*/}
                                            <div className="row mb-8">
                                                <div className="col-6">
                                                    <TextInput required label={t("--Common.Box number")} name="number" />
                                                </div>
                                                <div className="col-6">
                                                    <TextInput required label={t("--Common.Size")} name="area" />
                                                </div>
                                            </div>
                                            <div className="row mb-8">
                                                <div className="col-4">
                                                    <TextInput required label={t("--Common.Price")} name="monthlyPrice" />
                                                </div>
                                            </div>
                                            {/*begin::Actions*/}
                                            <div className="text-center">
                                                <button className="btn btn-white me-3" onClick={() => OnClose()}>{t('--Common.Cancel')}</button>
                                                <button
                                                    disabled={isSubmitting || !dirty || !isValid}
                                                    className="btn btn-primary">
                                                    <span className="indicator-label">{isValid ? t('--Common.Submit') : t('--Common.Form not valid')}</span>
                                                    <span className="indicator-progress">{t('--Common.Please wait...')}
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                </button>
                                            </div>
                                            {/*end::Actions*/}
                                            <div></div>
                                        </Form>
                                    )}
                                </Formik>}
                            {result?.isSuccess &&
                                <>
                                    <ModalConfirmation message={t('--Common.Saved correctly!')} success />
                                    <div className="swal2-actions mt-15">
                                        <button type="button" className="swal2-confirm btn btn-primary me-5" onClick={() => OnClose()}>Ok</button>
                                        {OnGoToItem &&
                                            <button type="button" className="swal2-confirm btn btn-primary" onClick={() => OnGoToItem!(result.value)}>{t('--Edit box.Take me to that box')}</button>}
                                    </div>
                                </>}
                            {result && !(result?.isSuccess) &&
                                <>
                                    <ModalConfirmation message={t('--Common.There was an error d', { boxNumber: itemLabel })} success={false} errors={result.errors.map(e => e.message)} />
                                    <div className="swal2-actions mt-15">
                                    <button type="button" className="swal2-confirm btn btn-primary me-5" onClick={() => OnClose()}>{t('--Common.Ok, forget about it')}</button>
                                        <button type="button" className="swal2-confirm btn btn-primary" onClick={() => setResult(null)}>{t("--Common.Let's try again")}</button>
                                    </div>
                                </>}
                        </div>
                        {/*end::Modal body*/}
                    </div>
                    {/*end::Modal content*/}
                </div>
                {/*end::Modal dialog*/}
            </div>
            <div className="modal-backdrop fade show"></div>
        </>
    )
})
