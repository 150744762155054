import React from 'react';
import { observer } from 'mobx-react-lite';
import { Reservation } from '../../../app/models/models';
import { NavLink } from 'react-router-dom';
import urls from '../../../app/common/urls';
import { useTranslation } from 'react-i18next';

interface Props {
	reservation: Reservation
}

export default observer(function ReservationCard({ reservation }: Props) {
	const [t] = useTranslation('common');
	return (
		<>
			<NavLink to={urls.contractDetails(reservation.id)} className="card border border-2 border-gray-300 border-hover">
				{/*begin::Card header*/}
				<div className="card-header border-0 pt-9">
					{/*begin::Card Title*/}
					<div className="card-title m-0"> {t('--Common.Reservation')}
					</div>
					{/*end::Car Title*/}
					{/*begin::Card toolbar*/}
					<div className="card-toolbar">
						<span className="badge badge-light-success fw-bolder me-auto px-4 py-3">{t('--Reservations.Future')}</span>
					</div>
					{/*end::Card toolbar*/}
				</div>
				{/*end:: Card header*/}
				{/*begin:: Card body*/}
				<div className="card-body p-9">
					{/*begin::Name*/}
					<div className="fs-3 fw-bolder text-dark">{reservation.customerFullName} - {t('--Common.Box')} #{reservation.boxNumber}</div>
					{/*end::Name*/}
					{/*begin::Description*/}
					<p className="text-gray-400 fw-bold fs-5 mt-1 mb-7">{t('--Reservations.Reservation created ')} {reservation.createdAt}</p>
					{/*end::Description*/}
					{/*begin::Info*/}
					<div className="d-flex flex-wrap mb-5">
						{/*begin::Due*/}
						<div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3">
							<div className="fs-6 text-gray-800 fw-bolder">{reservation.from}</div>
							<div className="fw-bold text-gray-400">{t('--Common.From')}</div>
						</div>
						{/*end::Due*/}
						{/*begin::Budget*/}
						<div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3">
							<div className="fs-6 text-gray-800 fw-bolder">{reservation.to}</div>
							<div className="fw-bold text-gray-400">{t('--Common.To')}</div>
						</div>
						{/*end::Budget*/}
					</div>
					{/*end::Info*/}
				</div>
				{/*end:: Card body*/}
			</NavLink>
		</>
	)
})