import React from 'react';
import { observer } from 'mobx-react-lite';
import { Form, Formik } from 'formik';
import { useStore } from '../../app/stores/store';
import TextInput from '../../app/elements/TextInput';
import { NavLink, useHistory } from 'react-router-dom';
import urls from '../../app/common/urls';
import Button from '../../app/elements/Button';
import { useTranslation } from 'react-i18next';

export default observer(function LoginPage() {
    const [t] = useTranslation('common');
    const { userStore } = useStore();
    const history = useHistory();
    return (
        <>
            <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed" style={{ backgroundImage: "url(assets/media/illustrations/progress-hd.png)" }}>
                {/*begin::Content*/}
                <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
                    {/*begin::Logo*/}
                    <a href="index.html" className="mb-12">
                        <img alt="Logo" src="custom/logo_large.png" />
                    </a>
                    {/*end::Logo*/}
                    {/*begin::Wrapper*/}
                    <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
                        {/*begin::Form*/}
                        <Formik initialValues={{ email: '', password: '', error: null }}
                            onSubmit={
                                (
                                    values,
                                    { setSubmitting, setErrors }

                                ) => {
                                    userStore.login(values)
                                        .then((result) => {
                                            setSubmitting(false);
                                            history.push(urls.boxesList())
                                        })
                                        .catch((err) => {
                                            setSubmitting(false);
                                            setErrors({ error: 'Invalid email or password' });
                                        })
                                }
                            }
                        >
                            {({ handleSubmit, isSubmitting, errors }) => (
                                <Form onSubmit={handleSubmit} autoComplete='off'>
                                    {/*begin::Heading*/}
                                    <div className="text-center mb-10">
                                        {/*begin::Title*/}
                                        <h1 className="text-dark mb-3">{t('--Authentication.Sign In to Self Stor')}</h1>
                                        {/*end::Title*/}
                                        {/*begin::Link*/}
                                        <div className="text-gray-400 fw-bold fs-4">{t('--Authentication.Forgot your login? P')}
                                        </div>
                                        {/*end::Link*/}
                                    </div>
                                    {/*begin::Heading*/}
                                    {/*begin::Input group*/}
                                    <div className="fv-row mb-10 fv-plugins-icon-container">
                                        {/*begin::Label*/}
                                        <label className="form-label fs-6 fw-bolder text-dark">{t('--Common.Email')}</label>
                                        {/*end::Label*/}
                                        {/*begin::Input*/}
                                        <TextInput className="form-control form-control-lg form-control-solid" type="text" name="email" />
                                        {/*end::Input*/}
                                        {errors.error &&
                                            <div className="fv-plugins-message-container invalid-feedback">{t('--Authentication.Invalid email or pas')}</div>}
                                    </div>
                                    {/*end::Input group*/}
                                    {/*begin::Input group*/}
                                    <div className="fv-row mb-10 fv-plugins-icon-container">
                                        {/*begin::Wrapper*/}
                                        <div className="d-flex flex-stack mb-2">
                                            {/*begin::Label*/}
                                            <label className="form-label fw-bolder text-dark fs-6 mb-0">{t('--Authentication.Password')}</label>
                                            {/*end::Label*/}
                                            {/*begin::Link*/}
                                            <NavLink to={urls.forgotPassword()} className="link-primary fs-6 fw-bolder">{t('--Authentication.Forgot Password ?')}</NavLink>
                                            {/*end::Link*/}
                                        </div>
                                        {/*end::Wrapper*/}
                                        {/*begin::Input*/}
                                        <TextInput className="form-control form-control-lg form-control-solid" type="password" name="password" />
                                        {/*end::Input*/}
                                        {errors.error &&
                                            <div className="fv-plugins-message-container invalid-feedback">{t('--Authentication.Invalid email or pas')}</div>}
                                    </div>
                                    {/*end::Input group*/}
                                    {/*begin::Actions*/}
                                    <div className="text-center">
                                        {/*begin::Submit button*/}
                                        <Button className="btn btn-lg btn-primary w-100 mb-5" disabled={isSubmitting} type='submit' text='Login' isSubmitting={isSubmitting} />
                                        {/*end::Submit button*/}
                                    </div>
                                    {/*end::Actions*/}
                                    <div></div></Form>)}
                        </Formik>
                        {/*end::Form*/}
                    </div>
                    {/*end::Wrapper*/}
                </div>
                {/*end::Content*/}
                {/*begin::Footer*/}
                <div className="d-flex flex-center flex-column-auto p-10">
                    {/*begin::Links*/}
                    <div className="d-flex align-items-center fw-bold fs-6">
                        <span>Powered by Cando Solutions</span>
                    </div>
                    {/*end::Links*/}
                </div>
                {/*end::Footer*/}
            </div>
        </>
    )
})