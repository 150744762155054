import React from 'react';
import { observer } from 'mobx-react-lite';

interface Props {
    onClick?(): void;
    text: string;
    disabled?: boolean;
    className?: string;
    notValidText?: string;
    type?: 'submit' | 'reset' | 'button';
    isSubmitting?: boolean

}

export default observer(function Button({ onClick, disabled, className, text, notValidText, type, isSubmitting }: Props) {
    return (
        <>
            <button
                onClick={onClick}
                disabled={disabled}
                className={className}
                type={type}>
                {!isSubmitting ?
                    (<span className="indicator-label">{!disabled ? text : (notValidText ?? text)}</span>)
                    :
                    (<span className="indicator-label">Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>)
                }

            </button>
        </>
    )
})