import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import '../../app/layout/map.css'
import { useState } from 'react';
import { useStore } from '../../app/stores/store';
import MapBoxDetails from './MapBoxDetails';
import { Box } from '../../app/models/models';
import { IconDown, IconLeft, IconReset, IconRight, IconUp, IconZoomIn, IconZoomOut } from '../../app/layout/icons/Icons';
import { useTranslation } from 'react-i18next';

interface BoxShape {
    id: string,
    x: number,
    y: number,
    width: number,
    height: number,
    boxNumber: string,
    area: number,
    isRented: boolean,
    upcomingContract: boolean;
    upcomingReservation: boolean;
}

export default observer(function MapPage() {
    const defaultViewPort = 230;
    const zoomStep = 50;
    const walkStep = defaultViewPort / 23;
    const [t] = useTranslation('common');

    const [zoomInDisabled, setZoomInDisabled] = useState(false);
    const [zoomOutDisabled, setZoomOutDisabled] = useState(true);
    const { boxStore } = useStore();

    const [currentBox, setCurrentBox] = useState<Box | undefined>(undefined);

    const selectBox = (id: string) => {
        boxStore.loadItem(id).then(() => {
            const box = boxStore.selectedBox;
            setCurrentBox(box);
        });
    }

    const [boxes, setBoxes] = useState<BoxShape[]>([])

    useEffect(() => {
        boxStore.filteringAndPaginationController.clearPredicateAndPagingToGetAll();
        boxStore.loadItems().then(() => {
            const tempBoxes: BoxShape[] = [];
            for (var j = 0; j < boxStore.items.length; j++) {
                const box = boxStore.items[j];
                tempBoxes.push(
                    {
                        id: box.id,
                        x: box.mapX,
                        y: box.mapY,
                        width: box.mapWidth,
                        height: box.mapHeight,
                        boxNumber: box.number,
                        area: box.area,
                        isRented: box.isRented,
                        upcomingContract: box.upcomingContract,
                        upcomingReservation: box.upcomingReservation
                    }
                )
            }
            setBoxes(tempBoxes);
        });
    }, [boxStore, boxStore.filteringAndPaginationController.clearPredicateAndPaging])

    const [viewport, setViewport] = useState(defaultViewPort);
    const [x, setX] = useState(-10);
    const [y, setY] = useState(-10);

    const zoomIn = () => {
        setViewport(viewport - zoomStep);
        setZoomOutDisabled(false);
        if (viewport <= 3 * zoomStep) { setZoomInDisabled(true) };
    }

    const zoomOut = () => {
        setZoomInDisabled(false);
        if (viewport + zoomStep >= defaultViewPort) {
            setZoomOutDisabled(true);
            setViewport(defaultViewPort);
            return;
        };
        setViewport(viewport + zoomStep)
    }
    const up = () => {
        setY(y - walkStep);
    }

    const down = () => {
        setY(y + walkStep);
    }

    const left = () => {
        setX(x - walkStep);
    }

    const right = () => {
        setX(x + walkStep);
    }

    const reset = () => {
        setX(-10);
        setY(-10);
        setViewport(defaultViewPort);
        setZoomOutDisabled(true);
        setZoomInDisabled(false)
    }

    const boxColor = (box: BoxShape) => {
        if (box.upcomingContract) return "#7239ea";
        if (box.upcomingReservation) return "#009ef7";
        return "#fcc522";
    }

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-8">
                        <div>
                            <div className="mb-4">
                                <button onClick={() => zoomIn()} disabled={zoomInDisabled} className="btn btn-sm btn-light btn-primary fw-bolder me-2 mb-2">
                                    <IconZoomIn size='1' />
                                    {t('--Map.Zoom in')}
                                </button>
                                <button onClick={() => zoomOut()} disabled={zoomOutDisabled} className="btn btn-sm btn-light btn-primary fw-bolder me-2 mb-2">
                                    <IconZoomOut size='1' />
                                    {t('--Map.Zoom out')}
                                </button>
                                <button onClick={() => up()} className="btn btn-sm btn-light btn-primary fw-bolder me-2 mb-2">
                                    <IconUp size='1' />
                                    {t('--Map.Up')}
                                </button>
                                <button onClick={() => down()} className="btn btn-sm btn-light btn-primary fw-bolder me-2 mb-2">
                                    <IconDown size='1' />
                                    {t('--Map.Down')}
                                </button>
                                <button onClick={() => left()} className="btn btn-sm btn-light btn-primary fw-bolder me-2 mb-2">
                                    <IconLeft size='1' />
                                    {t('--Map.Left')}
                                </button>
                                <button onClick={() => right()} className="btn btn-sm btn-light btn-primary fw-bolder me-2 mb-2">
                                    <IconRight size='1' />
                                    {t('--Map.Right')}
                                </button>
                                <button onClick={() => reset()} className="btn btn-sm btn-light btn-primary fw-bolder me-2 mb-2">
                                    <IconReset size='1' />
                                    {t('--Map.Reset')}
                                </button>
                            </div>
                        </div>
                        <div className="card mb-5 mb-xl-8">
                            {/*begin::Card body*/}
                            <div className="card-body p-0 pt-lg-1">
                                <div style={{ backgroundColor: 'white' }}>
                                    <svg viewBox={`${x} ${y} ${viewport} ${viewport * 0.717}`} version="1.1">
                                        <defs>
                                            <filter id="filter968" x="-.020401" y="-.02016" width="3.0408" height="3.0403" colorInterpolationFilters="sRGB">
                                                <feGaussianBlur stdDeviation="0.054853953" />
                                            </filter>
                                        </defs>
                                        {boxes.map(b => (
                                            <g key={b.id}>
                                                {b.isRented &&
                                                    <>
                                                        <rect onClick={() => selectBox(b.id)} x={b.x} y={b.y} width={b.width - 1} height={b.height - 1} ry="1.3029" fill="#dbdbdb" filter="url(#filter968)" />
                                                        {/* <path onClick={() => selectBox(b.id)} d={`m${b.x + 2} ${b.y + 2} 4.0 4.0`} fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
                                                        <path onClick={() => selectBox(b.id)} d={`m${b.x + 2} ${b.y + 6} 4.0-4.0`} fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" /> */}
                                                        <text onClick={() => selectBox(b.id)} x={b.x} y={b.y} fill="#ffffff" fontFamily="sans-serif" fontSize="1.933px" fontWeight="bold" strokeWidth=".26458" style={{ lineHeight: 1.25 }} ><tspan x={b.x + 1} y={b.y + b.height - 2 - 2} fill="#ffffff" fontFamily="sans-serif" fontWeight="bold" strokeWidth=".26458">{b.boxNumber}</tspan></text>
                                                        <text onClick={() => selectBox(b.id)} x={b.x} y={b.y} fill="#ffffff" fontFamily="sans-serif" fontSize="1.933px" fontWeight="bold" strokeWidth=".26458" style={{ lineHeight: 1.25 }} ><tspan x={b.x + 1} y={b.y + b.height - 2} fill="#ffffff" fontFamily="sans-serif" fontWeight="bold" strokeWidth=".26458">{b.area}m2</tspan></text>
                                                    
                                                    </>}

                                                {!b.isRented &&
                                                    <>
                                                        <rect onClick={() => selectBox(b.id)} x={b.x} y={b.y} width={b.width - 1} height={b.height - 1} ry="1.3029" fill={boxColor(b)} filter="url(#filter968)" />
                                                        <text onClick={() => selectBox(b.id)} x={b.x} y={b.y} fill="#ffffff" fontFamily="sans-serif" fontSize="1.933px" fontWeight="bold" strokeWidth=".26458" style={{ lineHeight: 1.25 }} ><tspan x={b.x + 1} y={b.y + b.height - 2 - 2} fill="#ffffff" fontFamily="sans-serif" fontWeight="bold" strokeWidth=".26458">{b.boxNumber}</tspan></text>
                                                        <text onClick={() => selectBox(b.id)} x={b.x} y={b.y} fill="#ffffff" fontFamily="sans-serif" fontSize="1.933px" fontWeight="bold" strokeWidth=".26458" style={{ lineHeight: 1.25 }} ><tspan x={b.x + 1} y={b.y + b.height - 2} fill="#ffffff" fontFamily="sans-serif" fontWeight="bold" strokeWidth=".26458">{b.area}m2</tspan></text>
                                                    </>}


                                            </g>
                                        ))}
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        {currentBox &&
                            <>
                                <MapBoxDetails box={currentBox!} />
                            </>
                        }
                    </div>
                </div>
            </div>
        </>
    )
})