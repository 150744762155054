import { createContext, useContext } from "react";
import { BoxStore } from "./boxStore";
import CommonStore from "./commonStore";
import ContractStore from "./contractStore";
import CustomerStore from "./customerStore";
import DiscountPlansStore from "./discountPlansStore";
import InvoiceStore from "./invoiceStore";
import ReservationStore from "./reservationStore";
import StatisticsStore from "./statisticsStore";
import UserStore from "./userStore";

interface Store {
    boxStore: BoxStore;
    contractStore: ContractStore;
    invoiceStore: InvoiceStore;
    customerStore: CustomerStore;
    reservationStore: ReservationStore;
    statisticsStore: StatisticsStore;
    userStore: UserStore;
    commonStore: CommonStore;
    discountPlansStore: DiscountPlansStore;
}

export const store: Store = {
    boxStore: new BoxStore(),
    contractStore: new ContractStore(),
    invoiceStore: new InvoiceStore(),
    customerStore: new CustomerStore(),
    reservationStore: new ReservationStore(),
    statisticsStore: new StatisticsStore(),
    userStore: new UserStore(),
    commonStore: new CommonStore(),
    discountPlansStore: new DiscountPlansStore()
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}