import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Form, Formik } from 'formik';
import { useStore } from '../../app/stores/store';
import TextInput from '../../app/elements/TextInput';
import { NavLink, useHistory } from 'react-router-dom';
import urls from '../../app/common/urls';
import Button from '../../app/elements/Button';
import Result from '../../app/api/result';
import ModalConfirmation from '../../app/elements/ModalConfirmation';
import { useTranslation } from 'react-i18next';

export default observer(function ForgotPasswordPage() {
    const [t] = useTranslation('common');
    const { userStore } = useStore();
    const [result, setResult] = useState<Result | null>(null);
    var history = useHistory();
    const onClose = () => {
        history.push(urls.root());
    }

    return (
        <>
            <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed" style={{ backgroundImage: "url(assets/media/illustrations/progress-hd.png)" }}>
                {/*begin::Content*/}
                <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
                    {/*begin::Logo*/}
                    <a href="index.html" className="mb-12">
                        <img alt="Logo" src="custom/logo_large.png" className="h-90px" />
                    </a>
                    {/*end::Logo*/}
                    {/*begin::Wrapper*/}
                    <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
                        {/*begin::Form*/}
                        {!result &&
                            <Formik initialValues={{ email: '', password: '', error: null }}
                                onSubmit={
                                    (values) => {
                                        userStore.resetPassword(values.email).then((resultOfCall) => {
                                            setResult(resultOfCall);
                                        });
                                    }
                                }
                            >
                                {({ handleSubmit, isSubmitting }) => (
                                    <Form onSubmit={handleSubmit} autoComplete='off'>
                                        {/*begin::Heading*/}
                                        <div className="text-center mb-10">
                                            {/*begin::Title*/}
                                            <h1 className="text-dark mb-3">{t('--Authentication.Forgot Password ?')}</h1>
                                            {/*end::Title*/}
                                            {/*begin::Link*/}
                                            <div className="text-gray-400 fw-bold fs-4">{t('--Authentication.Enter your email to ')}
                                            </div>
                                            {/*end::Link*/}
                                        </div>
                                        {/*begin::Heading*/}
                                        {/*begin::Input group*/}
                                        <div className="fv-row mb-10 fv-plugins-icon-container">
                                            {/*begin::Label*/}
                                            <label className="form-label fs-6 fw-bolder text-dark">{t('--Common.Email')}</label>
                                            {/*end::Label*/}
                                            {/*begin::Input*/}
                                            <TextInput className="form-control form-control-lg form-control-solid" type="text" name="email" />
                                            {/*end::Input*/}
                                            <div className="fv-plugins-message-container invalid-feedback"></div>
                                        </div>
                                        {/*end::Input group*/}

                                        {/*begin::Actions*/}
                                        <div className="d-flex flex-wrap justify-content-center pb-lg-0">
                                            {/*begin::Submit button*/}
                                            <NavLink className="btn btn-lg btn-default fw-bolder me-4" to={urls.root}>{t('--Common.Cancel')}</NavLink>
                                            <Button className="btn btn-lg btn-primary fw-bolder me-4" disabled={isSubmitting} type='submit' text={t('--Common.Submit')} isSubmitting={isSubmitting} />
                                            {/*end::Submit button*/}
                                        </div>
                                        {/*end::Actions*/}
                                    </Form>)}
                            </Formik>}
                        {result?.isSuccess &&
                            <>
                                <ModalConfirmation message={t('--Authentication.If your email is in ')} success />
                                <div className="swal2-actions mt-15">
                                    <button type="button" className="swal2-confirm btn btn-primary me-5" onClick={() => onClose()}>Ok</button>
                                </div>
                            </>}
                        {result && !(result?.isSuccess) &&
                            <>
                                <ModalConfirmation message={t('--Authentication.There was an error d')} success={false} errors={result.errors.map(e => e.message)} />
                                <div className="swal2-actions mt-15">
                                    <button type="button" className="swal2-confirm btn btn-primary me-5" onClick={() => onClose()}>{t('--Common.Ok, forget about it')}</button>
                                    <button type="button" className="swal2-confirm btn btn-primary" onClick={() => { setResult(null); }}>{t("--Common.Let's try again")}</button>
                                </div>
                            </>}
                        {/*end::Form*/}
                    </div>
                    {/*end::Wrapper*/}
                </div>
                {/*end::Content*/}
                {/*begin::Footer*/}
                <div className="d-flex flex-center flex-column-auto p-10">
                    {/*begin::Links*/}
                    <div className="d-flex align-items-center fw-bold fs-6">
                        <span>Powered by Cando Solutions</span>
                    </div>
                    {/*end::Links*/}
                </div>
                {/*end::Footer*/}
            </div>
        </>
    )
})