import React from 'react';
import { observer } from 'mobx-react-lite';
import { Reservation } from '../../../app/models/models';
import { NavLink } from 'react-router-dom';
import urls from '../../../app/common/urls';
import { useTranslation } from 'react-i18next';
interface Props {
    boxReservations: Reservation[]
}
export default observer(function BoxReservations({ boxReservations }: Props) {
    const [t] = useTranslation('common');
    return (
        <>
            {boxReservations.length !== 0 &&
                <div className="card card-xl-stretch mb-5 mb-xl-8">
                    {/*begin::Header*/}
                    <div className="card-header border-0 pt-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bolder fs-3 mb-1">{t('--Navigation.Reservations')}</span>
                        </h3>
                    </div>
                    {/*end::Header*/}
                    {/*begin::Body*/}
                    <div className="card-body py-3">
                        <div className="tab-content">
                            {/*begin::Tap pane*/}
                            <div className="tab-pane fade show active" id="kt_table_widget_6_tab_1">
                                {/*begin::Table container*/}
                                <div className="table-responsive">
                                    {/*begin::Table*/}
                                    <table className="table align-middle gs-0 gy-3">
                                        {/*begin::Table head*/}
                                        <thead>
                                            <tr>
                                                <th className="p-0 min-w-150px"></th>
                                                <th className="p-0 min-w-140px"></th>
                                                <th className="p-0 min-w-120px"></th>
                                            </tr>
                                        </thead>
                                        {/*end::Table head*/}
                                        {/*begin::Table body*/}
                                        <tbody>
                                            {boxReservations.map((r) => (
                                                <tr key={r.id}>
                                                    <td>
                                                        <NavLink to={urls.customerDetails(r.customerId)} className="text-dark fw-bolder text-hover-primary mb-1 fs-6">{r.customerFullName}</NavLink>
                                                        <span className="text-muted fw-bold d-block">{r.customerPhone}</span>
                                                    </td>
                                                    <td>
                                                        <span className="text-muted fw-bold d-block fs-7">{t('--BoxDetails.Period')}</span>
                                                        <span className="text-primary fw-bolder d-block fs-5">{r.from} - {r.to}</span>
                                                    </td>

                                                </tr>
                                            ))}
                                        </tbody>
                                        {/*end::Table body*/}
                                    </table>
                                </div>
                                {/*end::Table*/}
                            </div>
                            {/*end::Tap pane*/}
                        </div>
                    </div>
                    {/*end::Body*/}
                </div>}
        </>
    )
})
