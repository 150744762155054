import React from 'react';
import { observer } from 'mobx-react-lite';
import { Invoice } from '../models/models';
import { useTranslation } from 'react-i18next';

interface Props {
    invoice: Invoice
}

export default observer(function InvoiceBadge({ invoice: i }: Props) {
    const [t] = useTranslation('common');
    if (i.isPaid) return <span className="badge badge-light-success fw-bolder px-4 py-3">{t('--Common.Paid')}</span>;
    if (i.isDue) return <span className="badge badge-danger fw-bolder px-4 py-3">{t('--Common.Due')}!</span>;
    return <span className="badge badge-light-danger fw-bolder px-4 py-3">{t('--Common.Not paid')}</span>;
})