import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BoxStatistics } from '../../../app/models/models';

interface Props {
    boxStatistics: BoxStatistics
}

export default observer(function BoxTimeline({ boxStatistics }: Props) {
    const [t] = useTranslation('common');

    return (
        <>
            <div className="card card-xxl-stretch mb-5 mb-xl-10">
                {/*begin::Header*/}
                <div className="card-header align-items-center border-0 pt-9">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="fw-bolder mb-2 text-dark fs-2">{t('--BoxDetails.Box exents')}</span>
                        <span className="text-muted fw-bold fs-7">{t('--BoxDetails.Most recent and futu')}</span>
                    </h3>
                </div>
                {/*end::Header*/}
                {/*begin::Body*/}
                <div className="card-body pt-5">
                    {/*begin::Timeline*/}
                    <div className="timeline-label">
                        {boxStatistics.boxStatisticsEvents.map((e, idx) => (
                            <div className="timeline-item" key={idx}>
                                {/*begin::Label*/}
                                <div className="timeline-label fw-bolder text-gray-800 fs-6">{e.timeReadable}</div>
                                {/*end::Label*/}
                                {/*begin::Badge*/}
                                <div className="timeline-badge">
                                    <i className={`fa fa-genderless text-${e.level} fs-1`}></i>
                                </div>
                                {/*end::Badge*/}
                                {/*begin::Text*/}
                                <div className={`timeline-content ps-3 ${e.isBold ? 'text-gray-800 fw-bolder' : 'fw-normal text-muted'}`}>{e.description}</div>
                                {/*end::Text*/}
                            </div>
                        ))}
                    </div>
                    {/*end::Timeline*/}
                </div>
                {/*end: Card Body*/}
            </div>
        </>
    )
})