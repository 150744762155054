import React from 'react';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';
import { Box } from '../../app/models/models';
import urls from '../../app/common/urls';
import ProgressBar from '../../app/elements/ProgressBar';
import MapBoxDetailsUpcomingEvents from './MapBoxDetailsUpcomingEvents';
import { useTranslation } from 'react-i18next';

interface Props {
    box: Box
}

export default observer(function MapBoxDetails({ box }: Props) {
    const [t] = useTranslation('common');

    return (
        <>
            {/*begin::Card*/}
            <div className="card mb-5 mb-xl-8">
                {/*begin::Card body*/}
                <div className="card-body pt-0 pt-lg-1">
                    {/*begin::Summary*/}
                    {/*begin::Card*/}
                    <div className="">
                        {/*begin::Card body*/}
                        <div className="card-body d-flex flex-center flex-column pt-8 p-2 px-0">
                            {/*begin::Name*/}
                            <NavLink to={urls.boxDetails(box.id)}>
                                <span className="fs-1 text-gray-800 fw-bolder mb-3">#{box.number} ({box.area} m2)</span>
                            </NavLink>
                            {/*end::Name*/}
                            {/*begin::Position*/}
                            <div className="mb-5 mt-5">
                                {/*begin::Badge*/}
                                {box.isRented &&
                                    <NavLink to={urls.boxDetails(box.id)}>
                                        <span className="badge badge-lg badge-light-primary d-inline">{t('--Common.Rented')}</span></NavLink>}
                                {!box.isRented &&
                                    <NavLink to={urls.boxDetails(box.id)}>
                                        <span className="badge badge-lg badge-light-primary d-inline">{t('--Common.Free to rent')}</span> </NavLink>}
                                {/*begin::Badge*/}
                            </div>
                            {/*end::Position*/}
                        </div>
                        {/*end::Card body*/}
                    </div>
                    {/*end::Card*/}
                    {/*end::Summary*/}
                    {box.isRented &&
                        <>
                            <div className="d-flex flex-stack fs-4 py-3">
                                <div className="fw-bolder">{t('--Contracts.Current contract dat')}</div>
                                <span data-bs-toggle="tooltip" data-bs-trigger="hover" title="Edit customer details">
                                    <NavLink to={urls.contractDetails(box.currentContractId)} className="btn btn-sm btn-light-primary">{t('--Common.View')}</NavLink>
                                </span>
                            </div>
                            <div className="separator"></div>
                            {/*begin::Details content*/}
                            <div className="collapse show">
                                <div className="pb-5 fs-6">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="fw-bolder mt-5 mb-3">{t('--Contracts.Progress')}</div>
                                            <ProgressBar percentage={box.currentContractPercentage} simple={true} />
                                        </div>
                                        <div className="col-6">
                                            <div className="fw-bolder mt-5">{t('--Contracts.Period')}</div>
                                            <div className="text-gray-600">{box.currentContractFrom} - {box.currentContractTo}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-stack fs-4 py-3">
                                <div className="fw-bolder">{t('--BoxDetails.Current box owner')}
                                </div>
                                <span>
                                    <NavLink to={urls.customerDetails(box.currentOwnerId)} className="btn btn-sm btn-light-primary">{t('--Common.View')}</NavLink>
                                </span>
                            </div>
                            <div className="separator"></div>
                            <div className="collapse show">
                                <div className="pb-5 fs-6">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="fw-bolder mt-5">{t('--Common.Name')}</div>
                                            <div className="text-gray-600">{box.currentOwnerName}</div>
                                        </div>
                                        <div className="col-6">
                                            <div className="fw-bolder mt-5">{t('--Common.Phone')}</div>
                                            <div className="text-gray-600">{box.currentOwnerPhone}</div>
                                        </div>
                                    </div>
                                    <div className="fw-bolder mt-5">{t('--Common.Email')}</div>
                                    <div className="text-gray-600"> {box.currentOwnerEmail} </div>
                                </div>
                            </div>
                        </>}
                    <MapBoxDetailsUpcomingEvents id={box.id} />
                    <NavLink to={urls.boxDetails(box.id)} className={`btn btn-${box.isRented?'light-':''}success btn-shadow-hover font-weight-bolder w-100 py-3 mb-5`} href="/contracts/60d8bc0d-54a3-41c2-d304-08d95be04673">{t('--Invoices.Go to box')}</NavLink>
                </div>
                {/*end::Card body*/}
            </div >
            {/*end::Card*/}
        </>
    )
})

