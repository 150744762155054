import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BoxStatistics } from '../../../app/models/models';

interface Props {
    boxStatistics: BoxStatistics
}

export default observer(function BoxStatisticsChart({ boxStatistics }: Props) {
    const [t] = useTranslation('common');

    return (
        <>
            {/*begin::Mixed Widget 6*/}
            <div className="card card-xl-stretch mb-xl-8 pt-9">
                {/*begin::Beader*/}
                <div className="card-header border-0">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bolder fs-2 mb-2">{t('--BoxDetails.Box Statistics')}</span>
                        <span className="text-muted fw-bold fs-7">{t('--BoxDetails.Stats of the current')}</span>
                    </h3>
                    <div className="card-toolbar">
                       
                    </div>
                </div>
                {/*end::Header*/}
                {/*begin::Body*/}
                <div className="card-body p-0 d-flex flex-column">
                    {/*begin::Stats*/}
                    <div className="card-px pt-5 pb-10 flex-grow-1">
                        {/*begin::Row*/}
                        <div className="row g-0 mt-5 mb-10">
                            {/*begin::Col*/}
                            <div className="col">
                                <div className="d-flex align-items-center me-2">
                                    {/*begin::Symbol*/}
                                    <div className="symbol symbol-50px me-3">
                                        <div className="symbol-label bg-light-info">
                                            {/*begin::Svg Icon | path: icons/duotone/Design/Color-profile.svg*/}
                                            <span className="svg-icon svg-icon-1 svg-icon-info">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <path d="M12,10.9996338 C12.8356605,10.3719448 13.8743941,10 15,10 C17.7614237,10 20,12.2385763 20,15 C20,17.7614237 17.7614237,20 15,20 C13.8743941,20 12.8356605,19.6280552 12,19.0003662 C11.1643395,19.6280552 10.1256059,20 9,20 C6.23857625,20 4,17.7614237 4,15 C4,12.2385763 6.23857625,10 9,10 C10.1256059,10 11.1643395,10.3719448 12,10.9996338 Z M13.3336047,12.504354 C13.757474,13.2388026 14,14.0910788 14,15 C14,15.9088933 13.7574889,16.761145 13.3336438,17.4955783 C13.8188886,17.8206693 14.3938466,18 15,18 C16.6568542,18 18,16.6568542 18,15 C18,13.3431458 16.6568542,12 15,12 C14.3930587,12 13.8175971,12.18044 13.3336047,12.504354 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                                    <circle fill="#000000" cx="12" cy="9" r="5" />
                                                </svg>
                                            </span>
                                            {/*end::Svg Icon*/}
                                        </div>
                                    </div>
                                    {/*end::Symbol*/}
                                    {/*begin::Title*/}
                                    <div>
                                        <div className="fs-4 text-dark fw-bolder">{boxStatistics.totalRentDays} d.</div>
                                        <div className="fs-7 text-muted fw-bold">{t('--BoxDetails.Total rent')}</div>
                                    </div>
                                    {/*end::Title*/}
                                </div>
                            </div>
                            {/*end::Col*/}
                            {/*begin::Col*/}
                            <div className="col">
                                <div className="d-flex align-items-center me-2">
                                    {/*begin::Symbol*/}
                                    <div className="symbol symbol-50px me-3">
                                        <div className="symbol-label bg-light-danger">
                                            {/*begin::Svg Icon | path: icons/duotone/Home/Library.svg*/}
                                            <span className="svg-icon svg-icon-1 svg-icon-danger">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24" />
                                                        <path d="M5,3 L6,3 C6.55228475,3 7,3.44771525 7,4 L7,20 C7,20.5522847 6.55228475,21 6,21 L5,21 C4.44771525,21 4,20.5522847 4,20 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M10,3 L11,3 C11.5522847,3 12,3.44771525 12,4 L12,20 C12,20.5522847 11.5522847,21 11,21 L10,21 C9.44771525,21 9,20.5522847 9,20 L9,4 C9,3.44771525 9.44771525,3 10,3 Z" fill="#000000" />
                                                        <rect fill="#000000" opacity="0.3" transform="translate(17.825568, 11.945519) rotate(-19.000000) translate(-17.825568, -11.945519)" x="16.3255682" y="2.94551858" width="3" height="18" rx="1" />
                                                    </g>
                                                </svg>
                                            </span>
                                            {/*end::Svg Icon*/}
                                        </div>
                                    </div>
                                    {/*end::Symbol*/}
                                    {/*begin::Title*/}
                                    <div>
                                        <div className="fs-4 text-dark fw-bolder">{boxStatistics.averageContractDays} d.</div>
                                        <div className="fs-7 text-muted fw-bold">{t('--BoxDetails.Avg. Contract')}</div>
                                    </div>
                                    {/*end::Title*/}
                                </div>
                            </div>
                            {/*end::Col*/}
                        </div>
                        {/*end::Row*/}
                        {/*begin::Row*/}
                        <div className="row g-0 mt-5 mb-10">
                            {/*begin::Col*/}
                            <div className="col">
                                <div className="d-flex align-items-center me-2">
                                    {/*begin::Symbol*/}
                                    <div className="symbol symbol-50px me-3">
                                        <div className="symbol-label bg-light-success">
                                            {/*begin::Svg Icon | path: icons/duotone/Shopping/Cart3.svg*/}
                                            <span className="svg-icon svg-icon-1 svg-icon-success">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24" />
                                                        <path d="M12,4.56204994 L7.76822128,9.6401844 C7.4146572,10.0644613 6.7840925,10.1217854 6.3598156,9.76822128 C5.9355387,9.4146572 5.87821464,8.7840925 6.23177872,8.3598156 L11.2317787,2.3598156 C11.6315738,1.88006147 12.3684262,1.88006147 12.7682213,2.3598156 L17.7682213,8.3598156 C18.1217854,8.7840925 18.0644613,9.4146572 17.6401844,9.76822128 C17.2159075,10.1217854 16.5853428,10.0644613 16.2317787,9.6401844 L12,4.56204994 Z" fill="#000000" fillRule="nonzero" opacity="0.3" />
                                                        <path d="M3.5,9 L20.5,9 C21.0522847,9 21.5,9.44771525 21.5,10 C21.5,10.132026 21.4738562,10.2627452 21.4230769,10.3846154 L17.7692308,19.1538462 C17.3034221,20.271787 16.2111026,21 15,21 L9,21 C7.78889745,21 6.6965779,20.271787 6.23076923,19.1538462 L2.57692308,10.3846154 C2.36450587,9.87481408 2.60558331,9.28934029 3.11538462,9.07692308 C3.23725479,9.02614384 3.36797398,9 3.5,9 Z M12,17 C13.1045695,17 14,16.1045695 14,15 C14,13.8954305 13.1045695,13 12,13 C10.8954305,13 10,13.8954305 10,15 C10,16.1045695 10.8954305,17 12,17 Z" fill="#000000" />
                                                    </g>
                                                </svg>
                                            </span>
                                            {/*end::Svg Icon*/}
                                        </div>
                                    </div>
                                    {/*end::Symbol*/}
                                    {/*begin::Title*/}
                                    <div>
                                        <div className="fs-4 text-dark fw-bolder">{boxStatistics.customersTotalNumber}</div>
                                        <div className="fs-7 text-muted fw-bold">{t('--BoxDetails.Customers')}</div>
                                    </div>
                                    {/*end::Title*/}
                                </div>
                            </div>
                            {/*end::Col*/}
                            {/*begin::Col*/}
                            <div className="col">
                                <div className="d-flex align-items-center me-2">
                                    {/*begin::Symbol*/}
                                    <div className="symbol symbol-50px me-3">
                                        <div className="symbol-label bg-light-primary">
                                            {/*begin::Svg Icon | path: icons/duotone/Shopping/Barcode-read.svg*/}
                                            <span className="svg-icon svg-icon-1 svg-icon-primary">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                        <rect x="0" y="0" width="24" height="24" />
                                                        <rect fill="#000000" opacity="0.3" x="4" y="4" width="8" height="16" />
                                                        <path d="M6,18 L9,18 C9.66666667,18.1143819 10,18.4477153 10,19 C10,19.5522847 9.66666667,19.8856181 9,20 L4,20 L4,15 C4,14.3333333 4.33333333,14 5,14 C5.66666667,14 6,14.3333333 6,15 L6,18 Z M18,18 L18,15 C18.1143819,14.3333333 18.4477153,14 19,14 C19.5522847,14 19.8856181,14.3333333 20,15 L20,20 L15,20 C14.3333333,20 14,19.6666667 14,19 C14,18.3333333 14.3333333,18 15,18 L18,18 Z M18,6 L15,6 C14.3333333,5.88561808 14,5.55228475 14,5 C14,4.44771525 14.3333333,4.11438192 15,4 L20,4 L20,9 C20,9.66666667 19.6666667,10 19,10 C18.3333333,10 18,9.66666667 18,9 L18,6 Z M6,6 L6,9 C5.88561808,9.66666667 5.55228475,10 5,10 C4.44771525,10 4.11438192,9.66666667 4,9 L4,4 L9,4 C9.66666667,4 10,4.33333333 10,5 C10,5.66666667 9.66666667,6 9,6 L6,6 Z" fill="#000000" fillRule="nonzero" />
                                                    </g>
                                                </svg>
                                            </span>
                                            {/*end::Svg Icon*/}
                                        </div>
                                    </div>
                                    {/*end::Symbol*/}
                                    {/*begin::Title*/}
                                    <div>
                                        <div className="fs-4 text-dark fw-bolder">{boxStatistics.rentTimePercentage} %</div>
                                        <div className="fs-7 text-muted fw-bold">{t('--BoxDetails.Total Rent')}</div>
                                    </div>
                                    {/*end::Title*/}
                                </div>
                            </div>
                            {/*end::Col*/}
                        </div>
                        {/*end::Row*/}
                    </div>
                    {/*end::Stats*/}
                </div>
                {/*end::Body*/}
            </div>
            {/*end::Mixed Widget 6*/}
        </>
    )
})
