import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import AdminRequiredWrapper from '../common/AdminRequiredWrapper';
import urls from '../common/urls';
import { IconBoxes, IconContracts, IconCustomers, IconDashboard, IconDiscountPlans, IconInvoices, IconReservations } from './icons/Icons';

export default function NavBar() {
	const [t] = useTranslation('common');
	return (
		<>
			<div id="kt_aside" className="aside aside-dark aside-hoverable" data-kt-drawer="true"
				data-kt-drawer-name="aside" data-kt-drawer-activate="{default: true, lg: false}"
				data-kt-drawer-overlay="true" data-kt-drawer-width="{default:'200px', '300px': '250px'}"
				data-kt-drawer-direction="start" data-kt-drawer-toggle="#kt_aside_mobile_toggle">
				<div className="aside-logo flex-column-auto" id="kt_aside_logo">
					<NavLink to={urls.map()}>
						<img alt="Logo" src="custom/logo_navbar.png" className="h-40px logo" />
					</NavLink>
				</div>
				<div className="aside-menu flex-column-fluid">
					<div>
						<div className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500">
							<div className="menu-item">
								<NavLink className="menu-link"  to={urls.map()}>
									<span className="menu-icon">
										<IconDashboard size="1" />
									</span>
									<span className="menu-title">{t('--Navigation.Map')}</span>
								</NavLink>
							</div>
							<div className="menu-item">
								<NavLink className="menu-link" to={urls.boxesList()}>
									<span className="menu-icon">
										<IconBoxes size="1" />
									</span>
									<span className="menu-title">{t('--Navigation.Boxes')}</span>
								</NavLink>
							</div>
							<div className="menu-item">
								<NavLink className="menu-link" to={urls.customersList()}>
									<span className="menu-icon">
										<IconCustomers size="1" />
									</span>
									<span className="menu-title">{t('--Navigation.Customers')}</span>
								</NavLink>
							</div>
							<div className="menu-item">
								<NavLink className="menu-link" to={urls.contractsList()}>
									<span className="menu-icon">
										<IconContracts size="1" />
									</span>
									<span className="menu-title">{t('--Navigation.Contracts')}</span>
								</NavLink>
							</div>
							<div className="menu-item">
								<NavLink className="menu-link" to={urls.reservationsList()}>
									<span className="menu-icon">
										<IconReservations size="1" />
									</span>
									<span className="menu-title">{t('--Navigation.Reservations')}</span>
								</NavLink>
							</div>
							<div className="menu-item">
								<NavLink className="menu-link" to={urls.invoicesList()}>
									<span className="menu-icon">
										<IconInvoices size="1" />
									</span>
									<span className="menu-title">{t('--Navigation.Invoices')}</span>
								</NavLink>
							</div>
							<AdminRequiredWrapper>
								<div className="menu-item">
									<NavLink className="menu-link" to={urls.discountPlansList()}>
										<span className="menu-icon">
											<IconDiscountPlans size="1" />
										</span>
										<span className="menu-title">{t('--Navigation.Discount plans')}</span>
									</NavLink>
								</div>
							</AdminRequiredWrapper>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}