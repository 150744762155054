import React from 'react';
import RadialChartWidget from '../../../app/elements/RadialChartWidget';
import { BoxStatistics } from '../../../app/models/models';
import { observer } from "mobx-react-lite";

interface Props {
    boxStatistics: BoxStatistics
}

export default observer(function BoxContractPercentage({ boxStatistics }: Props) {
    return (
        <>
            {boxStatistics.currentContractId !== '00000000-0000-0000-0000-000000000000' &&
                <RadialChartWidget className="card-stretch gutter-b" contractId={boxStatistics.currentContractId} contractPercentage={boxStatistics.currentContractPercentage} note={boxStatistics.currentContractNote} />}
        </>
    )
})
