import React, { useEffect } from "react";
import ApexCharts from "apexcharts";
import { NavLink } from "react-router-dom";
import urls from "../common/urls";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

interface Props {
    className: string
    contractPercentage: number
    contractId: string
    note: string
}

export default observer(function RadialChartWidget({ className, contractId, contractPercentage, note }: Props) {
    const [t] = useTranslation('common');
    var danger = contractPercentage > 80;
    
    useEffect(() => {
        const element = document.getElementById("kt_mixed_widget_14_chart");
        if (!element) {
            alert('ret');
            return;
        }

        const options = getChartOptions(200, contractPercentage, danger);

        const chart = new ApexCharts(element, options);
        chart.render();
        return function cleanUp() {
            chart.destroy();
        };
    }, [contractPercentage, contractId, note, danger]);

    return (
        <div className={`card card-custom ${className}`}>
            {/* Header */}
            <div className="card-header border-0 pt-9">
                <h3 className="card-title fw-bolder fs-2">{t('--BoxDetails.Current contract pro')}</h3>
            </div>
            {/* Body */}
            <div className="card-body d-flex flex-column">
                <div className="flex-grow-1">
                    <div id="kt_mixed_widget_14_chart" style={{ height: "200px" }}></div>
                </div>
                <div className="pt-0">
                    {note &&
                        <p className="text-center font-weight-normal font-size-lg pb-0 fs-6">
                            <span className="badge badge-light-danger fs-8">Notes:</span>
                            {note}
                        </p>}
                    <NavLink to={urls.contractDetails(contractId)} className={`btn btn-${danger?'danger': 'success'} btn-shadow-hover font-weight-bolder w-100 py-3`}>{t('--BoxDetails.Contract details')}</NavLink>
                </div>
            </div>
        </div>
    );
})

function getChartOptions(height: number, percentage: number, danger: boolean) {
    const chartColor = danger ? "#f1416c":"#47BE7D"; 

    const options = {
        series: [percentage],
        chart: {
            height: height,
            type: 'radialBar',
        },
        plotOptions: {
            radialBar: {
                hollow: {
                    margin: 0,
                    size: "65%"
                },
                dataLabels: {
                    showOn: "always",
                    name: {
                        show: false,
                        fontWeight: "700",
                    },
                    value: {
                        color: "#464E5F",
                        fontSize: "30px",
                        fontWeight: "700",
                        offsetY: 12,
                        show: true
                    },
                },
                track: {
                    background: "#eeeeee",
                    strokeWidth: '100%'
                }
            }
        },
        colors: [chartColor],
        stroke: {
            lineCap: "round",
        },
        labels: ["Progress"]
    };
    return options;
}