import React from 'react';

export default function DashboardPage() {
    return (
        <>
            <div className="container" id="kt_content_container">
                
            </div>
        </>
    )
}